import React, { useState, useEffect, useRef } from "react";
import "./MultiSelector.css";
import { useLocation, useNavigate } from "react-router-dom";

const MultiSelectColor = ({
  data,
  onChange,
  selectedColors,
  onColorClearFilter,
}) => {
  const formattedData = data.map((el) => ({
    label: el?.color_name,
    value: el?.color_id,
  }));

  const location = useLocation();
  const navigate = useNavigate();
  const color_id = location.state?.color_id;

  const [selected, setSelected] = useState(selectedColors || []);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (color_id) {
      setSelected([color_id]);
      onChange([color_id]);
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [color_id, onChange, navigate, location.pathname]);

  useEffect(() => {
    if (selectedColors !== selected) {
      setSelected(selectedColors);
    }
  }, [selectedColors]);

  const handleCheckboxChange = (value) => {
    let newSelected;
    if (selected.includes(value)) {
      newSelected = selected.filter((item) => item !== value);
    } else {
      newSelected = [...selected, value];
    }
    setSelected(newSelected);
    onChange(newSelected);
  };

  const clearSelection = () => {
    setSelected([]);
    onChange([]);
    onColorClearFilter();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="filters__item--top js-dropdown dropdown dropdown--full"
      ref={dropdownRef}
      onMouseEnter={() => !isMobile && setIsOpen(true)}
      onMouseLeave={() => !isMobile && setIsOpen(false)}
    >
      <button
        className={`js-dropdown__trigger filters__item-title ${
          isOpen ? "btn_open" : ""
        }`}
        aria-haspopup="dialog"
        role="button"
        aria-expanded={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        Color
        <span className="h5 filters__count filters__count-size color_multi">
          {selected.length > 0 ? `(${selected.length})` : ""}
        </span>
        <span className="icon icon--arrow-down icon--sm">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            focusable="false"
            role="presentation"
          >
            <path d="M8 10.5a.751.751 0 0 1-.55-.24L4.2 6.76A.75.75 0 1 1 5.3 5.74L8 8.648l2.7-2.908a.75.75 0 1 1 1.1 1.021l-3.25 3.5a.751.751 0 0 1-.55.24z"></path>
          </svg>
        </span>
      </button>

      {isOpen && (
        <div
          className="filters__dropdown-menu js-dropdown__menu dropdown__menu open"
          role="dialog"
        >
          <div className="filters__listings filters__listings--cap-height">
            <div className="h5 u-margin-t--none u-margin-l--sm">Color</div>
            <ul className="ui-list ui-list--inline filters__size-list u-margin-l--sm">
              {formattedData.map((item) => (
                <li
                  key={item.value}
                  className={`${selected.includes(item.value) ? "active" : ""}`}
                >
                  <input
                    className="js-enterable u-screen-reader"
                    id={`color-top-Color__${item.value}`}
                    type="checkbox"
                    name="color"
                    value={item.value}
                    checked={selected.includes(item.value)}
                    onChange={() => handleCheckboxChange(item.value)}
                  />
                  <label
                    htmlFor={`color-top-Color__${item.value}`}
                    className="push-button u-margin-b--none"
                  >
                    {item.label}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="filters__actions">
            <button
              className="js-dropdown-close btn btn--sm u-margin-l--sm"
              onClick={() => setIsOpen(false)}
            >
              Done
            </button>
            <button className="js-clear-filter" onClick={clearSelection}>
              <span className="u-margin-l--xl link">Clear</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectColor;
