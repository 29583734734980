
import React from 'react';
const DeleteYourAccount = () => {
    return (
        <div class="main-content not-home">
            <div class="container">
                <div class="delete-acc">
                    <h2>Terms of Services</h2>
                    <p>THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES
                        MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE
                        ANY PHYSICAL OR DIGITAL SIGNATURES. BY USING THIS PLATFORM YOU INDICATE YOUR UNDERSTANDING AND
                        ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT USE THIS PLATFORM.
                    </p>
                    <h3>DEFINITIONS :</h3>
                    <p><strong>“Company”</strong> shall mean Fractal Systems LLC, a company registered in Delaware with
                        a registered office at 1209 Orange Street, Wilmington, DE 19801.</p>
                    <p><strong>“Platform”</strong> shall Seashels that provides a collection of online resources,
                        including classified advertisements, forums, related sites including the mobile application of
                        the platform;</p>
                    <p> <strong>“Products”</strong> shall mean the goods and services listed on the Platform by the Merchants for
                        the purposes of marketing and selling such goods and services to Buyers;</p>
                    <p><strong>Seashels ”;“Us”;“We”;“Our”</strong> shall mean the Company;</p>
                    <p><strong>“Users”, “Merchants” “Sellers” “You” or “Your”</strong>; shall mean any natural or legal
                        person who has access to and is using the Platform for the purpose of marketing or selling their
                        Products, including but not limited to such users who have not created a User Account and are
                        accessing the Platform without such a User Account; and</p>
                    <p><strong>“User Account”</strong> shall be the account, which the Users shall be required to create
                        with the Platform to avail the entire gamut of Services offered by it.</p>

                    <h3>INTRODUCTION :</h3>
                    <p>This Platform is operated by the Company under the brand Seashels . The Company offers this
                        Platform, including all information, tools and services available from this Platform to You, the
                        User, conditioned upon Your acceptance of all terms, conditions, policies and notices stated
                        here. The User’s use of the Platform and related tools and services thereof, including but not
                        limited to viewing information, acting on such information and the transactions that may be
                        implemented through the Platform, is governed by and shall be subject to these terms and
                        conditions (“<strong>Merchants’ Agreement</strong>” or “<strong>Terms of Service</strong>” or
                        <strong>“Terms”</strong>), the terms whereof are subject to change at any time, without prior
                        notice to You. Any new features or tools which are added to the current Platform shall also be
                        subject to the Terms of Service. To ensure that You are aware of the changes, please review this
                        Merchants’ Agreement and all the documents referred to hereunder periodically.
                    </p>
                    <p>If You continue to browse through this Platform, You are agreeing to be bound by the Terms which
                        along with the Policy govern Your relationship with Us. You confirm that You have also read and
                        have agreed and accepted to be bound by the terms and conditions incorporated in the terms of
                        the Privacy Policy, which shall be deemed to be a part of this Merchants’ Agreement.</p>
                    <p>In the event You are representing an incorporated entity, You hereby confirm that You have been
                        expressly authorized by such entity to consent this Merchants’ Agreement, and such entity agrees
                        to be bound by the terms hereunder.</p>
                    <p>This Merchants’ Agreement sets forth the legally binding terms of Your use of the Platform and
                        related services. By proceeding further, You confirm that You have read and have agreed and
                        accepted to be bound by the terms and conditions mentioned herein and incorporated in any
                        additional guidelines or rules applicable to particular services on the Platform
                        <strong>(“Additional Documents”)</strong>, which shall be deemed to be a part of this Merchants’
                        Agreement. In the event of any conflict between the terms of this Merchants’ Agreement and any
                        aforesaid Additional Documents, the Additional Documents shall prevail. If any of the terms of
                        this Merchants’ Agreement or of any of the Additional Documents are not acceptable to You,
                        please do not use the Platform. Your continued usage of the Platform after any change
                        constitutes Your acceptance of the amended Merchants’ Agreement.
                    </p>
                    <h3>SCOPE OF THE PLATFORM :</h3>
                    <p>The Platform is an e-portal/mobile based application for marketing, purchasing and selling of
                        Products. By visiting our Platform and/ or listing some Product for marketing purposes, You
                        engage in our “<strong>Service</strong>” and agree to be bound by the following terms and
                        conditions (“<strong>Terms of Service</strong>” , “<strong>Terms</strong>”), including those
                        additional terms and conditions and policies, as may be laid down under any Additional Documents
                        as referenced herein and/or available by hyperlink. These Terms of Service apply to all Users of
                        the Platform, including without limitation users who are browsers, vendors, customers,
                        merchants, and/ or contributors of data, content, Information, pictorial representations and/or
                        images (“<strong>Content</strong>”).</p>
                    <h3>EXCLUSION OF LIABILITY :</h3>
                    <p>You are aware and fully understand that Platform is only a platform for prospective buyers and
                        sellers and that:</p>
                    <ol>
                        <li>The Merchant <strong>shall alone have full legal and moral responsibility</strong> and
                            liability for all the Products listed and sold on the Platform. The Company shall bear no
                            liability or responsibility either for the Products being shown in catalogue or for the
                            actual goods or services provided to the Buyers by You and all such liability, including
                            financial and legal, shall solely lie with You;</li>
                        <li>The Merchant <strong>shall alone be responsible for procuring all licenses,
                            permits</strong>, passes etc. as required by applicable law for each of the Products
                            listed or sold through the Platform by You. The responsibility lies solely with You for
                            ensuring that the Products being listed or sold are permitted for advertising, listing and
                            sale under applicable laws;</li>
                        <li>It is Merchant’s responsibility to <strong>not list or sell anything that is not permitted
                            under applicable laws</strong> at the said point in time. An indicative list is provided
                            below under clause 7, but that list is not exhaustive. We have the right but not the
                            obligation to remove any item that is listed for sale from the Platform;</li>
                        <li>Platform is <strong>only facilitating payments on behalf of You</strong> and accepts no
                            liability associated with the listing or delivery of goods or services by You</li>
                        <li>The Merchant shall make every effort to <strong>ensure that images of the Products exactly
                            reflect the appearance of the Products in real life</strong> and the store display as
                            accurately as possible the colors and images of the Products; and</li>
                        <li><strong>The Merchant shall bear full liability and responsibility for dealing with any
                            returns or refunds</strong>. The responsibility for communicating, managing and
                            arranging for any refunds or returns lies entirely with You. The Company shall not be
                            responsible for dealing with any returns or refunds.</li>
                    </ol>
                    <h3>ONLINE STORE TERMS :</h3>
                    <ol>
                        <li>By agreeing to these Terms of Service, You represent that You are at least the age of
                            majority in Your state or province of residence You and You have given Us, Your consent to
                            allow any of Your minor dependents to use this Platform;</li>
                        <li>You may not, in the use of the Service, violate any laws in Your jurisdiction (including but
                            not limited to copyright laws);</li>
                        <li>You must not transmit any worms or viruses or any code of a destructive nature;</li>
                        <li>That the Content submitted by You shall not be derogatory, offensive or misleading in any
                            manner;</li>
                        <li>You are solely responsible for the Content that You upload, submit or send to or exchange on
                            the Platform. We shall under no circumstances be responsible for any claims made by a third
                            party in respect thereof;</li>
                        <li>Any fraudulent use of this Platform or applicable payment method to purchase the Products,
                            which causes any monetary loss to the Company as a result of Your action/inaction shall be
                            recovered from You. Without prejudice to the above, the Company reserves the right to
                            initiate legal proceedings against You for the fraudulent use of this Platform or for any
                            other unlawful act or omission in breach of this Merchants’ Agreement.</li>
                        <li>That images and pictorial representations on the Platform may be enhanced for advertising
                            purposes;</li>
                        <li>That We will not be responsible for verifying any Content posted on the Platform;</li>
                        <li>That You also understand and acknowledge that the use of the Platform requires internet
                            /mobile network connectivity. You shall bear the costs incurred to access and use the
                            Platform and We shall not, under any circumstances whatsoever, be responsible or liable for
                            such costs;</li>
                        <li>That You shall be solely responsible for all activities undertaken through Your User
                            Account, whether or not You have authorized such activities or actions and shall, at all
                            times, keep the Company indemnified in this regard;</li>
                        <li>That You shall be responsible for checking the Content, Product description and other
                            related information; and</li>
                        <li>If You are registering as a business entity, You represent that You are duly authorized by
                            the business entity to accept this Merchants’ Agreement and You have the authority to bind
                            that business entity to this Merchants’ Agreement.</li>
                    </ol>
                    <p>A breach or violation of any of the Terms will result in an immediate termination of Services and
                        may result in reporting to the law enforcement agencies.</p>
                    <h3>GENERAL CONDITIONS :</h3>
                    <ol>
                        <li>We reserve the right to refuse Service to anyone (without assigning any reason) at any time.
                        </li>
                        <li>You understand that Your Content (not including credit card information), may be transferred
                            unencrypted and involve (a) transmissions over various networks; and (b) changes to conform
                            and adapt to technical requirements of connecting networks or devices. Credit card
                            information is always encrypted during transfer over networks.</li>
                        <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
                            Service, use of the Service, or access to the Service or any contact on the Platform through
                            which the Service is provided, without express written permission by Us.</li>
                    </ol>
                    <p>The headings used in this Merchants’ Agreement are included for convenience only and will not
                        limit or otherwise affect these Terms.</p>

                    <h3>PROHIBITED USES :</h3>
                    In furtherance to Clause 4 (iii) above, <strong>You undertake and warrant that You</strong> will not
                    list the following Products on the Platform:
                    <ol>
                        <li>Adult products and pornographic materials (including child pornography) in any form (print,
                            audio/video, multimedia messages, images, photographs, etc.);</li>
                        <li>Alcohol;</li>
                        <li>Animals and wildlife products – examples include live animals, mounted specimens, and ivory;
                        </li>
                        <li>Artifacts;</li>
                        <li>Counterfeit goods and services infringing the IP (as defined below);</li>
                        <li>Crude oil;</li>
                        <li>Electronic surveillance equipment prohibited by law;</li>
                        <li>Embargoed goods from prohibited countries;</li>
                        <li>Endangered species of animals and plants, whether alive or dead;</li>
                        <li>Event tickets which are exempted from resale by law;</li>
                        <li>Firearms, weapons, and knives – examples include pepper spray, replicas, and stun guns;</li>
                        <li>Any financial services;</li>
                        <li>Food and healthcare items without holding requisite permits;</li>
                        <li>Grey market products;</li>
                        <li>Government related items/ equipment (like wireless equipment with frequency used by the
                            police, uniforms of Government officials including but not limited to the use by the police/
                            the Indian army, etc.)</li>
                        <li>Government issued documents like passports etc.;</li>
                        <li>Hazardous, restricted, or regulated materials – examples include batteries, fireworks, and
                            refrigerants;</li>
                        <li>Human remains and body parts;</li>
                        <li>IP in any form (including but not limited to music, movies, books, designs) for which the
                            Merchant do not hold the distribution rights;</li>
                        <li>Invoices and receipts (including blank and pre-filled);</li>
                        <li>Liquefied petroleum gas cylinder;</li>
                        <li>Lottery tickets;</li>
                        <li>Mailing lists and personal information;</li>
                        <li>Maps and literature where Indian external boundaries have been shown incorrectly;</li>
                        <li>Medicines, drugs and drug paraphernalia that require a registered medical practitioner’s
                            prescription;</li>
                        <li>Narcotic drugs and psychotropic substances as defined under the Narcotic Drugs and
                            Psychotropic Substances Act, 1985;</li>
                        <li>Offensive material which is likely to offend the sentiments of people whether on the grounds
                            of religion, race, caste, sex or place of birth, race, ethnicity, or culture;</li>
                        <li>Radioactive materials;</li>
                        <li>Reptile skins;</li>
                        <li>Sex determination Kit as under the Pre-Conception and Pre-Natal Diagnostic Techniques Act,
                            1994;</li>
                        <li>Stocks and securities;</li>
                        <li>Real estate;</li>
                        <li>Radioactive materials;</li>
                        <li>Stolen property;</li>
                        <li>Tobacco;</li>
                        <li>Any other sanctioned or prohibited items or services as per applicable laws; and</li>
                        <li>Any other item deemed unfit by Company.</li>
                    </ol>
                    <p>In addition to other prohibitions as set forth in the Terms of Service, You are prohibited from
                        using the Platform or its Content:</p>
                    <ol>
                        <li>for any unlawful purpose;</li>
                        <li>to solicit others to perform or participate in any unlawful acts;</li>
                        <li>to violate any international, federal, provincial or state regulations, rules, laws, or any
                            applicable ordinances;</li>
                        <li>to infringe upon or violate our intellectual property rights or the intellectual property
                            rights of others;</li>
                        <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate
                            based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or
                            disability;</li>
                        <li>to submit false or misleading information;</li>
                        <li>to upload or transmit viruses or any other type of malicious code that will or may be used
                            in any way that will affect the functionality or operation of the Service or of any related
                            Platform, other Platforms, or the internet;</li>
                        <li>to collect or track the personal information of others;</li>
                        <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                        <li>for any obscene or immoral purpose; or</li>
                        <li>to interfere with or circumvent the security features of the Service or any related
                            Platform, other Platforms, or the internet. We reserve the right to terminate Your use of
                            the Service or any related Platform for violating any of the prohibited uses.</li>
                    </ol>
                    <h3>TERMS OF USE:</h3>
                    <p>You acknowledge that certain parts of this Platform are available only if You register on this
                        Platform by following the registration process mentioned under this Merchants’ Agreement.</p>

                    <h3>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION:</h3>
                    <ol>
                        <li>We are not responsible if information made available on the Platform is not accurate or not
                            complete or not current. The material on the Platform is provided for general information
                            only and should not be relied upon or used as the sole basis for making decisions without
                            consulting primary and more accurate sources of information. Any reliance on the material on
                            the Platform is at your own risk; and</li>
                        <li>We reserve the right to modify the contents of the Platform at any time, but We have no
                            obligation to update any information on the Platform. You agree that it is Your
                            responsibility to monitor changes to Our Platform.</li>
                    </ol>
                    <h3>MODIFICATIONS TO THE SERVICE AND PRICES:</h3>
                    <p>We reserve the right at any time to modify or discontinue the Service (or any part or content
                        thereof) without notice at any time. We shall not be liable to You or to any third-party for any
                        modification, price change, suspension or discontinuance of the Service.</p>

                    <h3>RIGHT TO ACCESS:</h3>
                    <p>To order products you must be over 18 years of age and possess a valid credit method of payment.
                        By placing an order, you are promising that all details you provide are true and accurate, that
                        you are over 18 years of age, that you are authorized to use your selected method of payment to
                        place your order and that there are sufficient funds in the relevant account to cover the cost
                        of your order.</p>

                    <h3>PRICE OF THE PRODUCTS:</h3>
                    <p>The Merchant undertakes and warrants that at all times, the sale price of Product provided by the
                        Merchant for listing and display on the Platform shall be:</p>
                    <ol>
                        <li>Inclusive of all taxes; and</li>
                        <li>the exact price at which the Product would be sold to the Buyer.</li>
                    </ol>
                    <p>We will in no way be responsible for assisting You in relation to any claims with respect to tax
                        payable on the Products sold by You on the Platform.</p>
                    <p>You agree to indemnify, defend and hold harmless the Company and our parent, subsidiaries,
                        affiliates, form any loss arising out of or in relation to any : (i) claims with respect to
                        taxes to be paid on the Products sold by You on the Platform; and/or (ii) with respect to any
                        misrepresentation of the sale price of the Products listed by You on the Platform.</p>
                    <h3>PRODUCTS :</h3>
                    <p>We reserve the right, but not the obligation, to limit the sales of our Services to any person,
                        geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We
                        reserve the right to limit the quantities of any Products that You offer. All descriptions of
                        Products or Product pricing are subject to change at any time without notice, at our sole
                        discretion. We reserve the right to discontinue any Product at any time. Any offer for any
                        Product made on this Platform is void where prohibited.</p>
                    <p>We do not warrant that the quality of any Products, information, or other material purchased or
                        obtained by any Buyers from You will meet the Buyers’ expectations, or that any errors in the
                        Service will be corrected. You will be solely responsible for the same.</p>
                    <h3>OPTIONAL TOOLS :</h3>
                    <p>We may provide You with access to third-party tools which We neither monitor nor have any control
                        over nor do We provide any input. You acknowledge and agree that we provide access to such tools
                        on an “as is basis” and “as available” without any warranties, representations or conditions of
                        any kind and without any endorsement. We shall have no liability whatsoever arising from or
                        relating to your use of optional third-party tools.</p>
                    <p>Any use by You of optional tools offered through the Platform is entirely at Your own risk and
                        discretion and You should ensure that You are familiar with and approve of the terms on which
                        tools are provided by the relevant third-party provider(s).</p>
                    <p>We may also, in the future, offer new services and/or features through the Platform (including,
                        the release of new tools and resources). Such new features and/or services shall also be subject
                        to these Terms of Service.</p>

                    <h3>THIRD-PARTY LINKS :</h3>
                    <p>Certain content, products and services available via our Service may include materials from
                        third-parties.</p>

                    <p>Third-party links on this Platform may direct You to third-party Platforms that are not
                        affiliated with Us. We are not responsible for examining or evaluating the content or accuracy
                        and We do not warrant and will not have any liability or responsibility for any third-party
                        materials or Platforms, or for any other materials, products, or services of third-parties.</p>

                    <p>We are not liable for any harm or damages related to the purchase or use of goods, services,
                        resources, Content, or any other transactions made in connection with any third-party Platforms.
                        Please review carefully the third-party’s policies and practices and make sure You understand
                        them before You engage in any transaction. Complaints, claims, concerns, or questions regarding
                        third-party products should be directed to the third-party.</p>
                    <h3>USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS :</h3>

                    <p>If, at Our request, You send certain specific submissions (for example contest entries) or
                        without a request from Us, You send creative ideas, suggestions, proposals, plans, or other
                        materials, whether online, by email, by postal mail, or otherwise (collectively, “Comments”),
                        You agree that We may, at any time, without restriction, edit, copy, publish, distribute,
                        translate and otherwise use in any medium any comments that You forward to Us.</p>

                    <p>We are and shall be under no obligation to:</p>
                    <ol>
                        <li>maintain any Comments in confidence;</li>
                        <li>pay compensation for any Comments; or</li>
                        <li>respond to any Comments.</li>
                    </ol>

                    <p>We may, but have no obligation to, monitor, edit or remove Content that we determine in our sole
                        discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or
                        otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                    </p>

                    <p>You agree that your Comments will not violate any right of any third-party, including copyright,
                        trademark, privacy, personality or other personal or proprietary right. You further agree that
                        Your Comments will not contain libelous or otherwise unlawful, abusive or obscene material, or
                        contain any computer virus or other malware that could in any way affect the operation of the
                        Service or any related Platform. You may not use a false e-mail address, pretend to be someone
                        other than Yourself, or otherwise mislead us or third-parties as to the origin of any Comments.
                        You are solely responsible for any Comments You make and their accuracy. We take no
                        responsibility and assume no liability for any Comments posted by You or any third-party.</p>

                    <h3>PERSONAL INFORMATION :</h3>
                    <p>Your submission of personal information is governed by Our Privacy Policy.</p>

                    <h3>ERRORS, INACCURACIES AND COMISSIONS:</h3>
                    <p>Occasionally there may be information on the Platform or in the Service that contains
                        typographical errors, inaccuracies or omissions that may relate to product descriptions,
                        pricing, promotions, offers, product shipping charges, transit times and availability. We
                        reserve the right to correct any errors, inaccuracies or omissions, and to change or update
                        information or cancel orders if any information in the Service or on any related Platform is
                        inaccurate at any time without prior notice We undertake no obligation to update, amend or
                        clarify information in the Service or on any related Platform, including without limitation,
                        pricing information, except as required by law. No specified update or refresh date applied in
                        the Service or on any related Platform, should be taken to indicate that all information in the
                        Service or on any related Platform has been modified or updated.</p>

                    <h3>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY:</h3>
                    <p>We do not guarantee, represent or warrant that your use of our Service will be uninterrupted,
                        timely, secure or error-free. We do not warrant that the results that may be obtained from the
                        use of the service will be accurate or reliable.</p>

                    <p>You agree that from time to time we may remove the Service for indefinite periods of time or
                        cancel the Service at any time, without notice to You.</p>

                    <p>You expressly agree that Your use of, or inability to use, the service is at Your sole risk. We
                        are not responsible for ensuring that the sale price and other consideration to be received from
                        the Buyer (“Sale Price”) for sale of Products listed on the Platform to the Buyers. Any Service
                        provided to you by Us is without any representation, warranties or conditions of any kind,
                        either express or implied, including all implied warranties or conditions of due receipt of Sale
                        Price and non-infringement.</p>

                    <p>In no case shall the Company, Our directors, officers, employees, affiliates, agents,
                        contractors, interns, suppliers, service providers or licensors be liable for any injury, loss,
                        claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any
                        kind, including, without limitation lost profits, lost revenue, lost savings, loss of data,
                        replacement costs, or any similar damages, whether based in contract, tort (including
                        negligence), strict liability or otherwise, arising from Your use of any of the Service, or for
                        any other claim related in any way to Your use of the Service, including, but not limited to,
                        any errors or omissions in any content, or any loss or damage of any kind incurred as a result
                        of the use of the Service or any Content (or product) posted, transmitted, or otherwise made
                        available via the Service, even if advised of their possibility. In states or jurisdictions that
                        do not allow the exclusion or the limitation of liability for consequential or incidental
                        damages, in such states or jurisdictions, our liability shall be limited to the maximum extent
                        permitted by law.</p>

                    <h3>COPYRIGHT AND TRADEMARK:</h3>
                    <p>Each time You upload the Content, You grant the Company a worldwide non-exclusive, transferable,
                        royalty free license to use any such content including by way of distribution, storage, hosting,
                        sub-license, reproduction, communication, creation of derivative works, and modification of such
                        content. You accept that such license will not terminate upon Your deletion or removal of the
                        Content or other uploaded content in respect of which it is granted; and shall continue to be
                        used by the Company, on the Platform.</p>

                    <p><strong>You agree and confirm that:</strong></p>

                    <ol>
                        <li>All copyright, database right and all other proprietary rights, title and interest in all
                            Content and or information presented on this Platform (“IP”) is owned by and/or licensed to
                            Us or owned by and/or licensed to the manufacturer of the Products and/or Services or is
                            owned by and/or licensed to the person uploading such Content and is or may be protected or
                            covered by copyright, trade mark, intellectual property law and/or other proprietary rights,
                            unless expressly stated otherwise.</li>
                        <li>No extracts of this Platform or part thereof shall be displayed, printed or downloaded by
                            You or with Your assistance, for any reason, including without limitation to commercialize
                            any IP in any way.</li>
                        <li>Your use of this Platform does not confer on You or any other party, any license or other
                            rights under the intellectual property or other proprietary rights of the Company, the
                            manufacturer of the Products and/ or Services and/or of any third party, whether implied or
                            otherwise.</li>
                        <li>The Company shall not be held liable for any claims relating to infringement of any
                            intellectual property rights in relation to the Products and/or Services their content
                            and/or Content.</li>
                    </ol>

                    <h3>INDEMNIFICATION :</h3>
                    <p>You agree to indemnify, defend and hold harmless the Company and our parent, subsidiaries,
                        affiliates, partners, officers, directors, agents, contractors, licensors, service providers,
                        subcontractors, suppliers, interns and employees, harmless from any claim or demand, including
                        reasonable attorneys’ fees, made by any third-party due to or arising out of Your breach of
                        these Terms of Service or the documents they incorporate by reference, or Your violation of any
                        law or the rights of a third-party.</p>
                    <h3>SEVERABILITY :</h3>
                    <p>In the event that any provision of these Terms of Service is determined to be unlawful, void or
                        unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted
                        by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms
                        of Service, such determination shall not affect the validity and enforceability of any other
                        remaining provisions.</p>
                    <h3>TERMINATION :</h3>
                    <p>These Terms of Service are effective unless and until terminated by either You or Us. If in our
                        sole judgment You fail, or we suspect that You have failed, to comply with any term or provision
                        of these Terms of Service, we also may terminate this Merchants’ Agreement at any time without
                        notice and You will remain liable for all amounts due up to and including the date of
                        termination; and/or accordingly may deny You access to our Services (or any part thereof).</p>
                    <h3>ENTIRE AGREEMENT :</h3>
                    <p>Our failure to exercise or enforce any right or provision of these Terms of Service shall not
                        constitute a waiver of such right or provision.</p>
                    <p>These Terms of Service and any policies or operating rules posted by Us on this Platform or in
                        respect to The Service constitutes the entire agreement and understanding between You and Us and
                        govern Your use of the Service, superseding any prior or contemporaneous agreements,
                        communications and proposals, whether oral or written, between You and Us (including, but not
                        limited to, any prior versions of the Terms of Service).</p>
                    <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against
                        the drafting party.</p>
                    <h3>GOVERNING LAW :</h3>
                    <p>These Terms of Service and any separate agreements whereby we provide You Services shall be
                        governed by and construed in accordance with the laws of Delaware.</p>
                    <h3>FORCE MAJEURE :</h3>
                    <p>We shall not be held liable for any of Our obligations under the Merchants’ Agreement due to
                        reasons beyond our control such as down time of servers, viruses, strikes, technical snags,
                        system compatibility, natural calamities, acts of war, terror etc. You agree not to hold Us
                        liable for any delay or adverse effect caused due to the occurrence of such an event.</p>
                    <h3>CHANGES TO TERMS OF SERVICE :</h3>
                    <p>You can review the most current version of the Terms of Service at any time at this page. We
                        reserve the right, at our sole discretion, to update, change or replace any part of these Terms
                        of Service by posting updates and changes to our Platform. It is Your responsibility to check
                        our Platform periodically for changes. Your continued use of or access to our Platform or the
                        Service following the posting of any changes to these Terms of Service constitutes acceptance of
                        those changes.</p>
                    <h3>CONTACT INFORMATION :</h3>
                    <p>For any queries about Terms of Service, you can call us at 917-310-1171 or email with us on <a
                        href="mailto:crm@seashels.com">crm@seashels.com</a></p>

                </div>

            </div>
        </div >
    );
};

export default DeleteYourAccount;