import React, { useEffect } from "react";
import { useCurrency } from "../../context/CurrencyContext";

const CurrencySelector = () => {
  const {
    currencyList,
    selectedCurrency,
    setCurrencyPreference,
    isLoading,
    setSelectedCurrency,
  } = useCurrency();

  useEffect(() => {
    if (!selectedCurrency) {
      const usdCurrency = currencyList.find(
        (currency) => currency.code === "USD"
      );
      if (usdCurrency) {
        setSelectedCurrency(usdCurrency.currency_id);
        localStorage.setItem("currencyCode", usdCurrency.code);
        localStorage.setItem("currencySymbol", usdCurrency.symbol);
      }
    }
  }, [currencyList, selectedCurrency, setSelectedCurrency]);

  const handleChange = async (event) => {
    const newCurrencyId = event.target.value;
    const newCurrencyCode =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-curcode"
      );
    const newCurrencySymbol =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-cursymbol"
      );
    localStorage.setItem("currencyCode", newCurrencyCode);
    localStorage.setItem("currencySymbol", newCurrencySymbol);

    try {
      await setCurrencyPreference(newCurrencyId);
    } catch (error) {
      console.error("Error setting currency preference:", error);
    }
  };

  return (
    <select value={selectedCurrency || ""} onChange={handleChange}>
      {currencyList.map((currency) => (
        <option
          key={currency.currency_id}
          data-cursymbol={currency.symbol}
          data-curcode={currency.code}
          value={currency.currency_id}
        >
          {currency.code}
        </option>
      ))}
    </select>
  );
};

export default CurrencySelector;
