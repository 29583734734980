// src/PaymentModal.js
import React, { useState } from "react";
import Modal from "react-modal";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

Modal.setAppElement("#root");

const PaymentModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
    } else {
      console.log("PaymentMethod:", paymentMethod);
      setErrorMessage("");
      setIsProcessing(false);
      closeModal();
    }
  };

  return (
    <div>
      <button onClick={openModal}>Open Payment Popup</button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Payment Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
          },
        }}
      >
        <h2>Complete Your Payment</h2>
        <form onSubmit={handleSubmit}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          <button type="submit" disabled={!stripe || isProcessing}>
            {isProcessing ? "Processing..." : "Pay"}
          </button>
          {errorMessage && <div>{errorMessage}</div>}
        </form>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default PaymentModal;
