import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import api from "../../api";
import Pagination from "../pagination/Pagination";

const ITEMS_PER_PAGE = 120;

const StoreListing = ({
  apiEndpoint = "/store/get_store_list",
  pageTitle = "Popular Store",
}) => {
  const { logout } = useAuth();
  const { tag_id, tag_type } = useParams();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const defaultImage = "https://via.placeholder.com/150";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const api_token = localStorage.getItem("apiToken");
        const response = await api.get(apiEndpoint, {
          params: {
            api_token: api_token,
            tag_type: tag_type,
            tag_id: tag_id,
          },
        });

        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else if (response.data.status === 401) {
          logout();
          navigate("/login");
        }

        const data = response.data;

        const processedProducts =
          data.store_list?.map((item) => ({
            id: item.id,
            store_uuid: item.store_uuid,
            image: item.store_logo || defaultImage,
            alt: item.store_name,
            title: item.store_name,
          })) || [];

        setProducts(processedProducts);
        setFilteredProducts(processedProducts);
        setTotalPages(Math.ceil(processedProducts.length / ITEMS_PER_PAGE));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [apiEndpoint, tag_id, tag_type]);

  useEffect(() => {
    const filtered = products.filter((product) =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
    setTotalPages(Math.ceil(filtered.length / ITEMS_PER_PAGE));
    setCurrentPage(1);
  }, [searchTerm, products]);

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSearchTerm("");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastProduct = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstProduct = indexOfLastProduct - ITEMS_PER_PAGE;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  if (error) {
    return <p>Error: {error}</p>;
  }

  const isPaginationVisible = totalPages > 1 && filteredProducts.length > 0;

  return (
    <div className="main-content">
      <div className="wear-offer">
        <div className="container">
          <div className="store-list">
            <div className="heading">
              <h2>{pageTitle}</h2>

              <div className="store-search">
                <button type="submit" className="searchButton">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8321 13.0044L10.3502 9.52245C11.2991 8.36196 11.7656 6.88117 11.6533 5.38636C11.541 3.89155 10.8584 2.49709 9.7468 1.49142C8.63519 0.485748 7.17956 -0.0541959 5.681 -0.0167273C4.18245 0.0207412 2.75562 0.632756 1.69565 1.69272C0.635685 2.75269 0.0236709 4.17952 -0.0137977 5.67807C-0.0512662 7.17663 0.488678 8.63226 1.49435 9.74387C2.50002 10.8555 3.89448 11.538 5.38929 11.6504C6.8841 11.7627 8.36489 11.2961 9.52538 10.3473L13.0073 13.8292C13.1173 13.9355 13.2647 13.9943 13.4176 13.9929C13.5706 13.9916 13.7169 13.9302 13.825 13.8221C13.9332 13.7139 13.9945 13.5676 13.9959 13.4147C13.9972 13.2617 13.9384 13.1144 13.8321 13.0044ZM5.83638 10.5001C4.9134 10.5001 4.01115 10.2264 3.24372 9.71364C2.47629 9.20086 1.87815 8.47203 1.52494 7.61931C1.17173 6.76658 1.07932 5.82827 1.25938 4.92303C1.43945 4.01778 1.8839 3.18626 2.53655 2.53362C3.18919 1.88097 4.02071 1.43652 4.92596 1.25645C5.8312 1.07639 6.76951 1.1688 7.62224 1.52201C8.47496 1.87522 9.20379 2.47336 9.71657 3.24079C10.2294 4.00822 10.503 4.91047 10.503 5.83345C10.5017 7.0707 10.0095 8.25688 9.13468 9.13175C8.25981 10.0066 7.07363 10.4987 5.83638 10.5001Z"
                      fill="#001017"
                    ></path>
                  </svg>
                </button>
                <input
                  type="search"
                  placeholder="Search by store name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img
                  src="/images/cross.png"
                  className="close"
                  alt=""
                  onClick={handleClearFilter}
                />
              </div>
            </div>

            <ul>
              {loading ? (
                [...Array(ITEMS_PER_PAGE / 2)].map((_, index) => (
                  <li key={index}>
                    <Skeleton height={200} width={375} />
                    <Skeleton
                      width={180}
                      height={30}
                      style={{ marginTop: 10 }}
                    />
                  </li>
                ))
              ) : currentProducts.length > 0 ? (
                currentProducts.map((store, index) => (
                  <li key={`${store.id}-${index}`}>
                    <Link to={`/visit-store/${store.store_uuid}`}>
                      <img
                        src={store.image || defaultImage}
                        alt={store.alt}
                        onError={(e) => (e.target.src = defaultImage)}
                      />
                    </Link>

                    <div className="brand">
                      <h4>{store.title}</h4>
                    </div>
                  </li>
                ))
              ) : (
                <p>No stores found</p>
              )}
            </ul>
          </div>
          {loading ? (
            <div className="pagination-skeleton">
              <Skeleton height={40} width={200} />
            </div>
          ) : isPaginationVisible ? (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StoreListing;
