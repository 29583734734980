import React from 'react';
import PhoneInput from 'react-phone-input-2';

const LoginForm = ({
    phone,
    email,
    password,
    otp,
    showOtpField,
    showPasswordField,
    loading,
    error,
    handlePhoneChange,
    handleEmailChange,
    handleGetOtp,
    handleResendOtp,
    togglePasswordField,
    handleProceed,
    handleLogin,
    setOtp,
    setPassword,
    showForgotPassword,
    showProceedButton
}) => {
    return (
        <form onSubmit={handleLogin} autoComplete="off">
            <div className="input-row">
                <PhoneInput
                    country={'us'}
                    value={phone}
                    onChange={handlePhoneChange}
                    inputStyle={{ width: '100%' }}
                    disabledCountryCode
                    inputProps={{
                        name: `phone_${Math.random()}`,
                        id: `phone_${Math.random()}`,
                        autoComplete: 'off',
                    }}
                />
            </div>
            <span>or</span>
            <div className="input-row">
                <input
                    type="email"
                    placeholder="Email Id"
                    value={email}
                    onChange={handleEmailChange}
                    name={`email_${Math.random()}`}
                    id={`email_${Math.random()}`}
                    autoComplete="off"
                />
            </div>

            <div className="btn-row">
                <ul>
                    <li>
                        <button
                            type="button"
                            id="otp"
                            onClick={handleGetOtp}
                        >
                            Get OTP
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            onClick={togglePasswordField}
                        >
                            Enter Password
                        </button>
                    </li>
                </ul>
            </div>

            {showPasswordField && (
                <>
                    <div className="input-row">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name={`password_${Math.random()}`}
                            id={`password_${Math.random()}`}
                            autoComplete="off"
                        />
                    </div>
                    <a href="/reset-password" className="forgot-pass" style={{ display: showForgotPassword ? 'block' : 'none' }}>
                        Forgot password?
                    </a>
                    {showProceedButton && (
                        <div className="input-submit">
                            <button
                                type="button"
                                onClick={handleProceed}
                            >
                                Proceed
                            </button>
                        </div>
                    )}
                </>
            )}
            {showOtpField && (
                <>
                    <div className="input-row otp">
                        <input
                            type="number"
                            placeholder="Enter OTP Here"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            name={`otp_${Math.random()}`}
                            id={`otp_${Math.random()}`}
                            autoComplete="off"
                        />
                        <button type="button" onClick={handleResendOtp}>
                            Resend OTP
                        </button>
                    </div>
                    <div className="input-submit">
                        <input
                            type="submit"
                            value={loading ? 'Logging in...' : 'Sign in'}
                            disabled={loading}
                        />
                    </div>
                </>
            )}
            {error && <div className="error-message">{error}</div>}
        </form>
    );
};

export default LoginForm;
