import { useContext } from "react";
import UserLocationContext from "./UserLocationContext";

const useUserLocation = () => {
  const context = useContext(UserLocationContext);
  if (!context) {
    throw new Error(
      "useUserLocation must be used within a UserLocationProvider"
    );
  }
  return context;
};

export default useUserLocation;
