// useAuth.js
import { useState } from "react";
import api from "../api";

export const useAuth = () => {
  const [user, setUser] = useState(null);

  const login = async (token, role) => {};

  return { user, login };
};
