// components/StoreBanner.js
import React from "react";
import Skeleton from "react-loading-skeleton";
import LazyLoadImage from "../LazyLoadImage";

const StoreBanner = ({ bannerUrl, thumburldata, loading }) => {
  const defaultImage = "https://via.placeholder.com/150";

  return (
    <div className="store-banner">
      {loading ? (
        <Skeleton height={530} />
      ) : (
        <LazyLoadImage
          thumbnailSrc={thumburldata}
          fullImageSrc={bannerUrl}
          alt="store image"
          onError={(e) => (e.target.src = defaultImage)}
        />
      )}
    </div>
  );
};

export default StoreBanner;
