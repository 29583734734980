//src/context/CurrencyContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../api";
import { useAuth } from "./AuthContext";

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
  const { apiToken, userRole } = useAuth();
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState("");

  useEffect(() => {
    const fetchCurrencies = async () => {
      if (!apiToken) {
        return;
      }

      try {
        let token = apiToken;
        if (userRole === "guest") {
          if (!token.startsWith("guest_")) {
            token = "guest_" + token;
          }
        }

        const response = await api.get("/currency/get_currency_list", {
          params: { api_token: token },
        });
        if (response && response.data && response.data.currency_list) {
          setCurrencyList(response.data.currency_list);
        } else {
          console.error("Currency list is undefined in the response");
          setCurrencyList([]);
        }
        const storedCurrency = localStorage.getItem("selectedCurrency");
        if (storedCurrency) {
          setSelectedCurrency(Number(storedCurrency));
        } else {
          setSelectedCurrency(
            currencyList.length ? currencyList[1].currency_id : null
          );
          localStorage.setItem(
            "currencyId",
            currencyList.length ? currencyList[1].currency_id : 2
          );
        }
      } catch (error) {
        console.error("Error fetching currency data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrencies();
  }, [apiToken, userRole]);

  const setCurrencyPreference = async (currencyId) => {
    try {
      localStorage.setItem("selectedCurrency", currencyId);
      setSelectedCurrency(currencyId);
      const currency = currencyList.find((c) => c.currency_id === currencyId);
      if (currency) {
        setSelectedCurrencyCode(currency.currency_code);
      }

      const response = await api.post(
        "/currency/set_user_currency_preference",
        {
          currency_id: currencyId,
          api_token: apiToken,
        }
      );

      if (response && response.data && response.data.message) {
        localStorage.setItem("currencyId", currencyId);
        localStorage.setItem("cartCount", 0);

        window.location.reload();
      } else {
        console.error(
          "Failed to set currency preference:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error setting currency preference:", error);
    }
  };

  return (
    <CurrencyContext.Provider
      value={{
        currencyList,
        selectedCurrency,
        selectedCurrencyCode,
        setSelectedCurrency,
        setCurrencyPreference,
        loading,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }
  return context;
};
