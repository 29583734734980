import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../api";
const Breadcrumb = () => {
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  useEffect(() => {
    if (location.state) {
      const { categoryName, subCategoryName } = location.state;
      if (categoryName) setCategoryName(categoryName);
      if (subCategoryName) setSubCategoryName(subCategoryName);
    }
  }, [location.state]);

  const handleCategoryClick = () => {
    if (category_id && gender_id && segment_id) {
      const button = document.getElementById("clearButton");
      if (button) {
        button.click();
      }
      navigate(`/shop/${category_id}/${gender_id}/${segment_id}`);
    }
  };

  const handleSubCategoryClick = () => {
    const button = document.getElementById("clearButton");
    if (button) {
      button.click();
    }
  };
  const API_TOKEN = localStorage.getItem("apiToken");
  const fetchSubcategories = async () => {
    try {
      const params = {
        api_token: API_TOKEN,
        segment_id,
        gender_id,
      };

      if (
        category_id !== undefined &&
        category_id !== null &&
        category_id !== ""
      ) {
        params.category_id = category_id;
      }

      if (
        sub_category_id !== undefined &&
        sub_category_id !== null &&
        sub_category_id !== ""
      ) {
        params.sub_category_id = sub_category_id;
      } else {
        params.sub_category_id = "";
      }
      const response = await api.get(`/sku/get_subcategories`, {
        params,
      });
      console.log("API_TOKEN", response);
      if (response.data.status === 200) {
        setSubCategoryName(response?.data?.data[0]?.sub_category);
        setCategoryName(response?.data?.data[0]?.category);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    if (segment_id && category_id && gender_id) {
      fetchSubcategories();
    }
  }, [segment_id, category_id, gender_id]);
  return (
    <div className="breadcrumb">
      <div className="container">
        <ul>
          {/* <li>
            <span onClick={handleHomeClick} style={{ cursor: "pointer" }}>
              Home
            </span> &gt;
          </li> */}
          {/* <li>
           
            <span onClick={handleShopClick} >
              Shop
            </span> 
            {category_id && categoryName && (
        <span style={{ marginLeft: '5px' }}>&gt;</span> 
      )}
          </li> */}
          <li>
            {/* Category with onClick */}
            {category_id && categoryName && (
              <>
                <span
                  onClick={handleCategoryClick}
                  style={{ cursor: "pointer" }}
                >
                  {categoryName}
                </span>
                {sub_category_id && (
                  <span style={{ marginLeft: "5px" }}>&gt;</span>
                )}
              </>
            )}
          </li>

          <li>
            {/* Sub-category with onClick */}
            {sub_category_id && subCategoryName && (
              <>
                <span
                // onClick={handleSubCategoryClick}
                // style={{ cursor: "pointer" }}
                >
                  {subCategoryName}
                </span>
              </>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
