import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import Logo from './Logo';
import Tabs from './Tabs';
import LoginForm from './login/LoginForm';
import SignupForm from './login/SignupForm';
import api from '../api';
import { convertPhoneNumber, CountryCodeWithString } from '../utils/phoneUtils';

const extractUuid = (guestToken) => {
    return guestToken.split('_')[1];
};
const AuthPage = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const [activeTab, setActiveTab] = useState('login');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOtpField, setShowOtpField] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showProceedButton, setShowProceedButton] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('userUid') && localStorage.getItem('LoggedIn') && localStorage.getItem('userRole') === 'shopper') {
            navigate('/');
        }
    }, [navigate]);

    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
        // Reset state on tab switch
        setShowOtpField(false);
        setShowPasswordField(false);
        setShowForgotPassword(false);
        setShowProceedButton(false);
    };

    const handlePhoneChange = (value) => {
        const phoneNumber = value.replace(/^\+/, '');
        const countryCode = phoneNumber.slice(0, 2);
        const numberWithoutCountryCode = phoneNumber.slice(2);
        const formattedPhone = `${countryCode}-${numberWithoutCountryCode}`;

        setPhone(formattedPhone);
        setEmail('');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setPhone('');
    };
    // Helper function to handle successful OTP login
    const handleSuccessfulLogin = (data) => {
        localStorage.setItem('apiToken', data.api_token);
        localStorage.setItem('userRole', data.role);
        localStorage.setItem('userName', data.name);
        localStorage.setItem('userEmail', data.email);
        localStorage.setItem('userUid', data.user_uid);
        localStorage.setItem('LoggedIn', true);
        login(data.api_token, data.role);

        setError(null);
        navigate('/');
    };
    // Helper function to handle password login after OTP confirmation
    const handlePasswordLogin = async (password, device_token) => {
        const username = email || (phone ? convertPhoneNumber(phone) : '');
        const country_code = phone ? CountryCodeWithString(phone) : 'US';
        const clientId = localStorage.getItem('clientId')

        const loginResponse = await api.post('/user/login', {
            otp: '',
            username,
            country_code,
            device_token: clientId,
            device_type: 'web',
            app_version: '1.0',
            guest_api_token: device_token,
            password,
        });

        console.log(loginResponse.data, 'Password login response');

        if (loginResponse.data?.status === 200 && loginResponse.data?.message === 'success') {
            handleSuccessfulLogin(loginResponse.data);
        } else {
            setError('OTP verification failed.');
        }
    };

    // Helper function to handle password-based signup
    const handlePasswordSignup = async (device_token) => {
        const signupResponse = await api.post('/user/signup', {
            email: email || '',
            phone: phone ? convertPhoneNumber(phone) : '',
            country_code: phone ? CountryCodeWithString(phone) : 'US',
            guest_token: device_token,
            password: newPassword,
            referral_code: '',
            gender: '',
            role: 'shopper',
            name: '',
        });

        console.log(signupResponse.data, 'Password signup response');

        if (signupResponse.data?.status === 400) {
            setError(signupResponse.data.message);
        } else {
            setShowOtpField(true);
            localStorage.setItem('user_uuid', signupResponse.data.user_uuid);
        }
    };


    const handleGetOtp = async (e) => {
        e.preventDefault();
        // setShowOtpField(true); // Show OTP field after clicking "Get OTP"
        // setShowPasswordField(false);
        // setShowForgotPassword(false);
        // setShowProceedButton(false);
        // Your OTP request logic
        try {
            let response;
            console.log(phone, 'phone');
            const username = email ? email : '' || (phone ? convertPhoneNumber(phone) : '');

            if (!username) {
                console.error('Error: Both email and phone are empty.');
                setError('Error: Both email and phone are empty.');
                return;
            }
            // Check if the user exists
            const checkUserResponse = await api.post('/user/check_user', {
                username: username,
            });


            if (activeTab === 'login') {
                // Handle login flow
                if (checkUserResponse.data.status === 200) {
                    // User exists, proceed with OTP login
                    response = await api.post('/user/otp_login', {
                        email: email || '',
                        phone: phone ? convertPhoneNumber(phone) : '',
                    });
                    setError(null);
                    setShowOtpField(true);
                } else if (checkUserResponse.data.status === 404 && checkUserResponse.data.message === 'User not found') {
                    // User does not exist, show an error message
                    setError("Your account doesn't exist with this phone or email. Please sign up first, then try again.");
                    // setShowOtpField(false); // Hide OTP field if shown
                } else {
                    throw new Error('Unexpected response from server.');
                }
            } else if (activeTab === 'signup') {
                // Handle signup flow
                if (checkUserResponse.data.status === 404 && checkUserResponse.data.message === 'User not found') {
                    // User does not exist, proceed with signup

                    const device_token = localStorage.getItem('apiToken');

                    let device_token_new;
                    if (device_token && !device_token.startsWith('guest_')) {
                        device_token_new = `guest_${device_token}`;
                        localStorage.setItem('apiToken', device_token_new);
                    }
                    response = await api.post('/user/signup', {
                        email: email || '',
                        phone: phone ? convertPhoneNumber(phone) : '',
                        country_code: phone ? CountryCodeWithString(phone) : 'US',
                        guest_token: device_token_new,
                        password: '',
                        referral_code: '',
                        gender: '',
                        role: 'shopper',
                        name: '',
                    });
                    console.log(response.data, 'Signup response');
                    setShowOtpField(true);
                    setError(null);

                } else if (checkUserResponse.data.status === 200) {
                    // User already exists, show an error message
                    setError("Your account already exists with this phone or email. Please sign in.");
                    // setShowOtpField(false); // Hide OTP field if shown
                } else {
                    throw new Error('Unexpected response from server.');
                }
            }
        } catch (err) {
            console.error('OTP request error:', err);
            setError('Failed to get OTP. Please try again.');
        } finally {
            setLoading(false);
        }

    };

    const togglePasswordField = () => {
        setShowPasswordField(true); // Show password field
        setShowForgotPassword(true); // Show forgot password link
        setShowProceedButton(true); // Show proceed button
        setShowOtpField(false); // Hide OTP field
    };

    const handleProceed = () => {
        setShowOtpField(true); // Show OTP related fields after proceeding
        setShowPasswordField(false);
        setShowForgotPassword(false);
        setShowProceedButton(false);

    };

    const handleLogin = async (e) => {
        e.preventDefault();
        // Your login logic


        if (otp) {
            try {
                const device_token = localStorage.getItem('apiToken');
                console.log(phone, 'phone');
                console.log(localStorage, 'localStorage');
                const uuid = extractUuid(device_token);
                console.log(device_token, 'device_token');

                const username = email || (phone ? convertPhoneNumber(phone) : '');
                const country_code = phone ? CountryCodeWithString(phone) : 'US';
                console.log(country_code, 'country_code');

                const otpResponse = await api.post('/user/login', {
                    otp,
                    username,
                    country_code: country_code,
                    device_token: device_token,
                    device_type: 'android',
                    app_version: '1.0',
                    guest_api_token: device_token,
                    // user_uuid: user_uid,
                    password: ''
                });



                console.log(otpResponse.data, 'OTP confirmation response');

                if (otpResponse.data && otpResponse.data.api_token && otpResponse.data.status === 200 && otpResponse.data.message === 'success') {
                    localStorage.setItem('apiToken', otpResponse.data.api_token);
                    localStorage.setItem('userRole', otpResponse.data.role);
                    localStorage.setItem('userName', otpResponse.data.name);
                    localStorage.setItem('userEmail', otpResponse.data.email);
                    localStorage.setItem('userUid', otpResponse.data.user_uid);
                    localStorage.setItem('LoggedIn', true);
                    login(otpResponse.data.api_token, otpResponse.data.role);
                    navigate('/');
                } else {
                    setError('OTP verification failed.');
                }
            } catch (err) {
                console.error('OTP confirmation error:', err);
                setError('Failed to confirm OTP.');
            } finally {
                setLoading(false);
            }
        } else if (password) {
            try {
                const device_token = localStorage.getItem('apiToken');
                console.log(phone, 'phone');

                const username = email || (phone ? convertPhoneNumber(phone) : '');
                const country_code = phone ? CountryCodeWithString(phone) : 'US';

                if (!username) {
                    console.error('Error: Both email and phone are empty.');
                    setError('Error: Both email and phone are empty.');
                    return;
                } else {

                    // Check if the user exists
                    const checkUserResponse = await api.post('/user/check_user', {
                        username: username,
                    });

                    console.log('checkUserResponse', checkUserResponse.data);
                    if (checkUserResponse.data.status === 200) {
                        const response = await api.post('/user/login', {
                            username,
                            password,
                            app_version: '1.0',
                            device_type: 'android',
                            device_token: device_token,
                            country_code,
                        });

                        console.log(response.data, 'Password login response');

                        if (response.data && response.data.api_token) {
                            localStorage.setItem('apiToken', response.data.api_token);
                            localStorage.setItem('userRole', response.data.role);
                            localStorage.setItem('userName', response.data.name);
                            localStorage.setItem('userUid', response.data.user_uid);
                            localStorage.setItem('userEmail', response.data.email);
                            localStorage.setItem('LoggedIn', true);
                            login(response.data.api_token, response.data.role);

                            navigate('/');
                        }

                        else {
                            setError(response.data.message);
                        }


                    } else if (checkUserResponse.data.status === 404 && checkUserResponse.data.message === 'User not found') {
                        // User does not exist, show an error message
                        setError("Your account doesn't exist with this phone or email. Please sign up first, then try again.");
                    } else {
                        throw new Error('Unexpected response from server.');
                    }
                }

            } catch (err) {
                console.error('Login error:', err);
                setError('Login failed.');
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(false);
            setError('Please enter either an OTP or a password.');
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        // Your signup logic

        try {
            // Retrieve device token from localStorage
            let device_token = localStorage.getItem('apiToken');

            // If device token doesn't start with 'guest_', add the prefix
            if (device_token && !device_token.startsWith('guest_')) {
                device_token = `guest_${device_token}`;
                localStorage.setItem('apiToken', device_token);
            }

            // OTP-based signup or login
            if (!showPasswordField) {
                console.log('Handling OTP-based signup/login');

                const username = email || (phone ? convertPhoneNumber(phone) : '');
                const country_code = phone ? CountryCodeWithString(phone) : 'US';

                // OTP Login API call
                const otpResponse = await api.post('/user/login', {
                    otp,
                    username,
                    app_version: '1.0',
                    country_code,
                    device_token,
                    device_type: 'android',
                    guest_api_token: device_token,
                    password: '',
                });

                console.log(otpResponse.data, 'OTP Login response');

                // Handle successful OTP login
                if (otpResponse.data?.status === 200 && otpResponse.data?.message === 'success') {
                    handleSuccessfulLogin(otpResponse.data);
                } else {
                    setShowOtpField(true);
                    setError('OTP verification failed.');
                }

            }
            // Handle OTP confirmation and password setup
            else if (newPassword && confirmPassword && otp) {
                const user_uid = localStorage.getItem('user_uuid');

                // Confirm OTP API call
                const otpConfirmResponse = await api.post('/user/confirm_otp', {
                    otp,
                    email: email || '',
                    phone: phone ? convertPhoneNumber(phone) : '',
                    user_uuid: user_uid,
                });

                console.log(otpConfirmResponse.data, 'OTP confirmation response');

                // If OTP verification is successful, handle password-based login
                if (otpConfirmResponse.data?.status === 200 && otpConfirmResponse.data?.message === 'OTP verified') {
                    await handlePasswordLogin(newPassword, device_token);
                } else {
                    setError('OTP verification failed.');
                }

            }
            // Handle direct password-based signup
            else if (newPassword && confirmPassword) {
                if (newPassword === confirmPassword) {
                    await handlePasswordSignup(device_token);
                } else {
                    setError('Passwords do not match.');
                }
            }
        } catch (err) {
            console.error('Sign-up error:', err);
            setError('Sign-up failed.');
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
    };

    const handleResendOtp = () => {
        // Your resend OTP logic

    };

    return (
        <div id="wrapper">
            <Logo />
            <div className="signin-block">
                <div className="container">
                    <Tabs activeTab={activeTab} handleTabSwitch={handleTabSwitch} />
                    <div id="tabs-content">
                        {activeTab === 'login' && (
                            <LoginForm
                                phone={phone}
                                email={email}
                                password={password}
                                otp={otp}
                                showOtpField={showOtpField}
                                showPasswordField={showPasswordField}
                                loading={loading}
                                error={error}
                                handlePhoneChange={handlePhoneChange}
                                handleEmailChange={handleEmailChange}
                                handleGetOtp={handleGetOtp}
                                handleResendOtp={handleResendOtp}
                                togglePasswordField={togglePasswordField}
                                handleProceed={handleProceed}
                                handleLogin={handleLogin}
                                setOtp={setOtp}
                                setPassword={setPassword}
                                showForgotPassword={showForgotPassword}
                                showProceedButton={showProceedButton}
                            />
                        )}
                        {activeTab === 'signup' && (
                            <SignupForm
                                phone={phone}
                                email={email}
                                otp={otp}
                                newPassword={newPassword}
                                confirmPassword={confirmPassword}
                                showOtpField={showOtpField}
                                showPasswordField={showPasswordField}
                                agreeTerms={agreeTerms}
                                loading={loading}
                                error={error}
                                handlePhoneChange={handlePhoneChange}
                                handleEmailChange={handleEmailChange}
                                handleGetOtp={handleGetOtp}
                                togglePasswordField={togglePasswordField}
                                handleProceed={handleProceed}
                                handleSignup={handleSignup}
                                setOtp={setOtp}
                                setNewPassword={setNewPassword}
                                setConfirmPassword={setConfirmPassword}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;
