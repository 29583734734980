import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Account = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { apiToken, userRole, logout } = useAuth(); // Access logout, apiToken, and userRole from useAuth hook

    // Function to get the active tab from the URL hash or default to 'tab1'
    const getInitialTab = () => {
        const hash = location.hash || '#tab1';
        return hash.substring(1); // Remove the '#' symbol
    };

    const [activeTab, setActiveTab] = useState(getInitialTab);

    useEffect(() => {
        setActiveTab(getInitialTab);
    }, [location]);

    // Handle logout
    const handleLogout = () => {
        logout();
        navigate('/home')

    };

    // Redirect to login if not authenticated
    useEffect(() => {
        if (!localStorage.getItem('userUid') && !localStorage.getItem('LoggedIn') && localStorage.getItem('userRole') === 'guest') {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div className="main-content not-home">
            <div className="container my-profile">
                <div className="my-profile">

                    <div className="profile-tabs">
                        <button id="nav-icon3" className="">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <ul id="profile-nav">

                            <li className={activeTab === 'tab1' ? 'active' : ''}>
                                <a href="#tab1" onClick={() => setActiveTab('tab1')}>Account Information</a>
                            </li>
                            <li className={activeTab === 'tab2' ? 'active' : ''}>
                                <a href="#tab2" onClick={() => setActiveTab('tab2')}>Account Activity</a>
                            </li>
                            <li className={activeTab === 'tab3' ? 'active' : ''}>
                                <a href="#tab3" onClick={() => setActiveTab('tab3')}>Caption</a>
                            </li>
                            <li className={activeTab === 'tab4' ? 'active' : ''}>
                                <a href="#tab4" onClick={() => setActiveTab('tab4')}>Change Login Details</a>
                            </li>
                            <li className={activeTab === 'tab5' ? 'active' : ''}>
                                <a href="#tab5" onClick={() => setActiveTab('tab5')}>Privacy Feature</a>
                            </li>
                            <li className={activeTab === 'tab6' ? 'active' : ''}>
                                <a href="#tab6" onClick={() => setActiveTab('tab6')}>Account Deletion</a>
                            </li>
                            <li>
                                {/* Logout Button */}
                                <div className="logout-section">
                                    <button onClick={handleLogout} id="otp" className="logout-btn">Logout</button>
                                </div>
                            </li>
                        </ul>
                        <div id="profile-content">

                            {activeTab === 'tab1' && (
                                <div id="tab1" className="tab-content">
                                    <div className="pro-add">
                                        <img src="images/pro-img.png" alt="Profile" />
                                        <a href="#"><img src="images/add-profile.png" alt="Add Profile" /></a>
                                    </div>
                                    <div className="profile-fields">
                                        <form action="">
                                            <div className="field-row">
                                                <label htmlFor="loginId">Login ID*</label>
                                                <input type="text" value="Stylediva" id="loginId" />
                                            </div>
                                            <div className="field-row">
                                                <label htmlFor="profileName">Profile Name*</label>
                                                <input type="text" value="Stylediva" id="profileName" />
                                            </div>
                                            <div className="field-row">
                                                <label htmlFor="phoneNumber">Phone Number*</label>
                                                <input type="number" value="+91 1234567890" id="phoneNumber" />
                                            </div>
                                            <div className="field-row">
                                                <label htmlFor="email">Email*</label>
                                                <input type="email" value="styledive@mailinator.com" id="email" />
                                            </div>
                                            <div className="field-row">
                                                <label htmlFor="city">City*</label>
                                                <input type="text" value="Delhi" id="city" />
                                            </div>
                                            <div className="space">
                                                <span>or</span>
                                            </div>
                                            <div className="field-row">
                                                <label htmlFor="location">Location</label>
                                                <input type="text" value="Delhi" id="location" />
                                            </div>
                                            <div className="field-row dob">
                                                <label htmlFor="dob">Date of Birth*</label>
                                                <div className="input-group">
                                                    <select name="date" id="date">
                                                        <option value="5" selected>5</option>
                                                        <option value="10">10</option>
                                                        <option value="15">15</option>
                                                        <option value="20">20</option>
                                                    </select>
                                                    <select name="month" id="month">
                                                        <option value="Jan">Jan</option>
                                                        <option value="Feb">Feb</option>
                                                        <option value="March" selected>March</option>
                                                        <option value="April">April</option>
                                                    </select>
                                                    <select name="year" id="year">
                                                        <option value="1990">1990</option>
                                                        <option value="1995">1995</option>
                                                        <option value="1996" selected>1996</option>
                                                        <option value="1998">1998</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="field-row gender">
                                                <label htmlFor="gender">Gender</label>
                                                <div className="field-row inline">
                                                    <p>
                                                        <input type="radio" id="female" name="gender" defaultChecked />
                                                        <label htmlFor="female">Female</label>
                                                    </p>
                                                    <p>
                                                        <input type="radio" id="male" name="gender" />
                                                        <label htmlFor="male">Male</label>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="field-row submit">
                                                <button type="submit" className="pro-btn">Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'tab2' && (
                                <div id="tab2" className="tab-content">
                                    <table className="account-activity">
                                        <thead>
                                            <tr>
                                                <th scope="col" align="left">Event</th>
                                                <th scope="col" align="left">Transaction</th>
                                                <th scope="col" align="left">Current Credits</th>
                                                <th scope="col" align="right">Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-label="Event" align="left">
                                                    <p>Purchase <br />(10 Jan 2024)</p>
                                                </td>
                                                <td data-label="Transaction" align="left">
                                                    <ul>
                                                        <li>Cash<span>$0</span></li>
                                                        <li>Credits<span>$0</span></li>
                                                    </ul>
                                                </td>
                                                <td data-label="Current Credits" align="left">
                                                    <ul>
                                                        <li>Locked<span>$0</span></li>
                                                        <li>Available<span>$0</span></li>
                                                    </ul>
                                                </td>
                                                <td data-label="Details" align="right">
                                                    <a href="#" className="detail-btn">Details</a>
                                                </td>
                                            </tr>
                                            {/* Additional rows can be added similarly */}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {activeTab === 'tab3' && (
                                <div id="tab3" className="tab-content">
                                    <div className="coupon">
                                        <input type="text" className="captioninput" placeholder="Enter a Caption" />
                                        <a href="#" className="pro-btn">Update</a>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'tab4' && (
                                <div id="tab4" className="tab-content">
                                    <form action="#" className="passwords">
                                        <div className="field-row inline">
                                            <p>
                                                <input type="radio" id="password" name="loginOption" defaultChecked />
                                                <label htmlFor="password">Password</label>
                                            </p>
                                            <p>
                                                <input type="radio" id="phone" name="loginOption" />
                                                <label htmlFor="phone">Phone Number</label>
                                            </p>
                                            <p>
                                                <input type="radio" id="email" name="loginOption" />
                                                <label htmlFor="email">Email</label>
                                            </p>
                                        </div>
                                        <div className="field-row space-btw">
                                            <h4><span>Press to send OTP:</span> <a href="#" className="request-btn">Request</a></h4>
                                        </div>
                                        <div className="field-row pass">
                                            <input type="number" placeholder="Enter OTP" />
                                            <input type="password" placeholder="New Password" />
                                        </div>
                                        <div className="field-row submit">
                                            <button type="submit" className="pro-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            )}
                            {activeTab === 'tab5' && (
                                <div id="tab5" className="tab-content">
                                    <form action="#" className="change">
                                        <div className="field-row inline">
                                            <p>
                                                <input type="radio" id="public" name="privacy" defaultChecked />
                                                <label htmlFor="public">Public</label>
                                            </p>
                                            <p>
                                                <input type="radio" id="private" name="privacy" />
                                                <label htmlFor="private">Private</label>
                                            </p>
                                        </div>
                                        <div className="field-row submit">
                                            <button type="submit" className="pro-btn">Block User</button>
                                        </div>
                                    </form>
                                </div>
                            )}
                            {activeTab === 'tab6' && (
                                <div id="tab6" className="tab-content">
                                    <div className="delete-acc">
                                        <div className="delete-text">
                                            <h6>Delete Account</h6>
                                            <p>Deleting your account is permanent. When you delete your Seashels account, you won't be able to retrieve the content or information you've shared on Seashels.</p>
                                        </div>
                                        <div className="delete-action">
                                            <ul>
                                                <li><a href="#" className="pro-btn">Continue to Account Deletion</a></li>
                                                <li><a href="#">Cancel</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div> {/* END tabs-content */}
                    </div> {/* END tabs */}
                </div>
            </div>
        </div>
    );
};

export default Account;
