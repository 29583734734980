import React, { useState } from "react";
import PropTypes from "prop-types";
import SortSelect from "../multiSelect/SortSelect";
import MultiSelectSize from "../multiSelect/multiSelectSize";
import MultiSelectColor from "../multiSelect/multiSelectColor";
import MultiSelectPrice from "../multiSelect/multiSelectPrice";
import ClearAllFilterData from "../multiSelect/ClearAllFilterData";
import MultiInputPrice from "../multiSelect/MultiInputPrice";
import Skeleton from "react-loading-skeleton";

const StoreTabFilter = ({
  sortOptions,
  sizeOptions,
  priceOptions,
  colorOptions,
  onSortChange,
  onSizeChange,
  onPriceChange,
  onColorChange,
  onClearFilters,
  onPriceClearFilter,
  onSizeClearFilter,
  onColorClearFilter,
  sortOption,
  showAdditionalFilters,
  loading,
}) => {
  const [selectedSort, setSelectedSort] = useState(sortOption || "");
  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);

  return (
    <div className="tab-filter">
      <div className="filter-option">
        {loading ? (
          <div>
            <Skeleton width={155} />
          </div>
        ) : (
          <SortSelect
            onChange={(value) => {
              setSelectedSort(value);
              onSortChange(value);
            }}
            data={sortOptions}
            selectedValue={selectedSort}
          />
        )}

        {showAdditionalFilters && (
          <>
            {loading ? (
              <div>
                <Skeleton width={155} />
              </div>
            ) : (
              <MultiInputPrice
                selectedPriceValue={selectedPriceRange}
                onChange={(value) => {
                  setSelectedPriceRange(value);
                  onPriceChange(value);
                }}
                onPriceClearFilter={onPriceClearFilter}
              />
            )}

            {loading ? (
              <div>
                <Skeleton width={155} />
              </div>
            ) : (
              <MultiSelectSize
                onChange={(value) => {
                  setSelectedSize(value);
                  onSizeChange(value);
                }}
                data={sizeOptions}
                selectedSizes={selectedSize}
                onSizeClearFilter={onSizeClearFilter}
              />
            )}

            {loading ? (
              <div>
                <Skeleton width={155} />
              </div>
            ) : (
              <MultiSelectColor
                onChange={(value) => {
                  setSelectedColors(value);
                  onColorChange(value);
                }}
                data={colorOptions}
                selectedColors={selectedColors}
                onColorClearFilter={onColorClearFilter}
              />
            )}
          </>
        )}
      </div>

      <div className="clear">
        {loading ? (
          <div>
            <Skeleton width={155} />
          </div>
        ) : (
          <ClearAllFilterData
            onClearFilters={onClearFilters}
            setSelectedColors={setSelectedColors}
            setSelectedSizes={setSelectedSize}
            setSelectedPrice={setSelectedPrice}
            setSelectedPriceRange={setSelectedPriceRange}
            setSelectedSort={setSelectedSort}
          />
        )}
      </div>
    </div>
  );
};

StoreTabFilter.propTypes = {
  sortOptions: PropTypes.array.isRequired,
  sizeOptions: PropTypes.array.isRequired,
  priceOptions: PropTypes.array.isRequired,
  colorOptions: PropTypes.array.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onColorChange: PropTypes.func.isRequired,
  sortOption: PropTypes.string.isRequired,
  showAdditionalFilters: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default StoreTabFilter;
