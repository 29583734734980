// src/api.js
import axios from "axios";

// Create an Axios instance with default settings
const api = axios.create({
  baseURL: "https://global.seashels.com:7777/api",
  // baseURL: "https://development.seashels.com:7777/api",
  headers: {
    "Content-Type": "application/json",
    // 'Access-Control-Allow-Origin': 'https://shop.chautarirestaurant.com',
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
  },
});
api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // Handle the response data
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
