import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import Pagination from "../pagination/Pagination";

const ProfileGifts = ({ apiToken }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage] = useState(54);

  useEffect(() => {
    const fetchGifts = async () => {
      try {
        setLoading(true);
        const response = await api.get("/gift/get_user_gift_list", {
          params: {
            api_token: apiToken,
            page: currentPage,
            per_page: perPage,
          },
        });
        if (response.status === 401) {
          logout();
        } else if (response.status === 404) {
          setGifts([]);
          setTotalPages(0);
        } else if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const data = response.data;
          setGifts(data.gift_list || []);
          setTotalPages(Math.ceil(data.total_count / perPage));
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGifts();
  }, [apiToken, currentPage, perPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  if (loading) {
    return <p>Loading gifts...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="also-like latest my-profile-sec">
      {gifts.length === 0 ? (
        <p>No gifts available</p>
      ) : (
        <>
          <ul>
            {gifts.map((gift) => (
              <li key={gift.gift_id}>
                <Link to={`/product/${gift.sui}`}>
                  <img src={gift.image_url_thumb} alt={gift.product_title} />
                </Link>
                <h4>
                  <Link to={`/product/${gift.sui}`}>{gift.product_title}</Link>
                </h4>
                <div className="description">
                  <span className="price">
                    {gift.display_currency_symbol}
                    {gift.display_final_price}
                  </span>
                </div>
              </li>
            ))}
          </ul>
          {totalPages > 1 && (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage + 1}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProfileGifts;
