import React from "react";
import "./MultiSelector.css";

const ClearAllFilterData = ({
  onClearFilters,
  setSelectedColors,
  setSelectedSizes,
  setSelectedPrice,
  setSelectedPriceRange,
  setSelectedSort,
}) => {
  const clearSelection = () => {
    onClearFilters();
    setSelectedColors([]);
    setSelectedSizes([]);
    setSelectedPrice([]);
    setSelectedPriceRange([]);
    setSelectedSort("");
  };

  return (
    <div className="clear">
      <button
        onClick={clearSelection}
        className="clear-button"
        id="clearButton"
      >
        <span>CLEAR</span>
      </button>
    </div>
  );
};

export default ClearAllFilterData;
