import React, { useState, useEffect, useRef } from "react";
import "./MultiSelector.css";

const MultiInputPrice = ({
  data = [],
  onChange,
  selectedPriceValue,
  onPriceClearFilter,
}) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const currencysybmol = localStorage.getItem("currencySymbol") || "$";
  const dropdownRef = useRef(null);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if (
      typeof selectedPriceValue === "string" &&
      selectedPriceValue !== "null"
    ) {
      const [min, max] = selectedPriceValue.split("-");
      setMinPrice(min || "");
      setMaxPrice(max || "");
    } else {
      setMinPrice("");
      setMaxPrice("");
    }
  }, [selectedPriceValue]);

  const handlePriceChange = () => {
    if (minPrice || maxPrice) {
      onChange(`${minPrice}-${maxPrice}`);
    } else {
      onChange(null);
    }
  };

  const clearSelection = () => {
    setMinPrice("");
    setMaxPrice("");
    onChange(null);
    onPriceClearFilter();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="filters__item--top js-dropdown dropdown dropdown--full"
      ref={dropdownRef}
      onMouseEnter={() => !isMobile && setIsOpen(true)}
      onMouseLeave={() => !isMobile && setIsOpen(false)}
    >
      <button
        className={`js-dropdown__trigger filters__item-title ${
          isOpen ? "btn_open" : ""
        }`}
        aria-haspopup="dialog"
        role="button"
        aria-expanded={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        Price
        <span className="h5 filters__count filters__count-size price_multi">
          {minPrice || maxPrice
            ? `(${currencysybmol}${minPrice} - ${currencysybmol}${maxPrice})`
            : ""}
        </span>
        <span className="icon icon--arrow-down icon--sm">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            focusable="false"
            role="presentation"
          >
            <path d="M8 10.5a.751.751 0 0 1-.55-.24L4.2 6.76A.75.75 0 1 1 5.3 5.74L8 8.648l2.7-2.908a.75.75 0 1 1 1.1 1.021l-3.25 3.5a.751.751 0 0 1-.55.24z"></path>
          </svg>
        </span>
      </button>

      {isOpen && (
        <div
          className="filters__dropdown-menu js-dropdown__menu dropdown__menu open"
          role="dialog"
        >
          <div className="filters__listings filters__listings--cap-height">
            <div className="h5 u-margin-t--none u-margin-l--sm">Price</div>
            <div className="price-inputs">
              <div className="price-input">
                <span className="currency-symbol">{currencysybmol}</span>
                <input
                  type="number"
                  placeholder="Min Price"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
              </div>
              <div className="price-input">
                <span className="currency-symbol">{currencysybmol}</span>
                <input
                  type="number"
                  placeholder="Max Price"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="filters__actions">
            <button
              className="js-dropdown-close btn btn--sm u-margin-l--sm"
              onClick={() => {
                handlePriceChange();
                setIsOpen(false);
              }}
            >
              Done
            </button>
            <button className="js-clear-filter" onClick={clearSelection}>
              <span className="u-margin-l--xl link">Clear</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiInputPrice;
