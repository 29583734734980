import React from "react";

const StoreDynamicLink = async (
  store_uuid,
  store_name,
  store_profile_image
) => {
  const image = encodeURIComponent(store_profile_image.trim());
  const description = encodeURIComponent(`Profile of ${store_name}`.trim());
  const title = "Modestrend";

  const currentType = localStorage.getItem("userRole") || "guest";
  const user_uuid = localStorage.getItem("userUuid");
  const country_code = localStorage.getItem("userCountryCode") || "US";

  const API_KEY = "AIzaSyBmAr5tphOF7t3V48143Htw0G535wOP5xM";
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`;

  const dynamicLinkConfig = {
    dynamicLinkInfo: {
      link: `https://connect.seashels.com/api/v1.0/Link/profile?user_id=${encodeURIComponent(
        store_uuid.trim()
      )}&type=${encodeURIComponent(
        currentType.trim()
      )}&region=${encodeURIComponent(
        country_code.trim()
      )}&mediaUrl=${image}&description=${description}`,
      domainUriPrefix: "https://discover.seashels.com",
      androidInfo: {
        androidPackageName: "com.seashels.discover",
        androidFallbackLink: "https://connect.seashels.com",
        androidMinPackageVersionCode: "1",
      },
      iosInfo: {
        iosBundleId: "com.seashels.discover",
        iosFallbackLink: "https://connect.seashels.com",
        iosAppStoreId: "6463077820",
        iosMinimumVersion: "1.0",
      },
      socialMetaTagInfo: {
        socialTitle: title || "Modestrend",
        socialDescription:
          decodeURIComponent(description) ||
          "Profile of a store on Modestrend.",
        socialImageLink: store_profile_image.trim(),
      },
    },
    suffix: {
      option: "SHORT",
    },
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dynamicLinkConfig),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error("Error response:", errorResponse);
      throw new Error(
        `Error creating dynamic link: ${errorResponse.error.message}`
      );
    }

    const result = await response.json();

    return result.shortLink;
  } catch (error) {
    console.error("Error creating dynamic link:", error);
  }
};

export default StoreDynamicLink;
