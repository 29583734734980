// SignupForm.js
import React from 'react';
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2';

const SignupForm = ({
    phone,
    email,
    otp,
    newPassword,
    confirmPassword,
    showOtpField,
    showPasswordField,
    agreeTerms,
    loading,
    error,
    handlePhoneChange,
    handleEmailChange,
    handleGetOtp,
    togglePasswordField,
    handleSignup,
    setOtp,
    setNewPassword,
    setConfirmPassword,
    handleCheckboxChange
}) => (
    <div id="tab2" className="tab-content">
        <form onSubmit={handleSignup} autoComplete="off">
            <div className="input-row">
                <PhoneInput
                    country={'US'}
                    value={phone}
                    onChange={handlePhoneChange}
                    inputStyle={{ width: '100%' }}
                    disabledCountryCode
                    inputProps={{
                        name: `phone_${Math.random()}`,
                        id: `phone_${Math.random()}`,
                        autoComplete: 'off',
                    }}
                />
            </div>
            <span>or</span>
            <div className="input-row">
                <input
                    type="email"
                    placeholder="Email Id"
                    value={email}
                    onChange={handleEmailChange}
                    name={`email_${Math.random()}`}
                    id={`email_${Math.random()}`}
                    autoComplete="off"
                />
            </div>
            <div className="btn-row">
                <ul>
                    <li>
                        <button type="button" id="otp" onClick={handleGetOtp}>
                            Get OTP
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={togglePasswordField}>
                            Enter Password
                        </button>
                    </li>
                </ul>
            </div>
            {showOtpField && (
                <div className="input-row otp">
                    <input
                        type="number"
                        placeholder="123456"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        name={`otp_${Math.random()}`}
                        id={`otp_${Math.random()}`}
                        autoComplete="off"
                    />
                </div>
            )}
            {showPasswordField && (
                <>
                    <div className="input-row">
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            name={`newPassword_${Math.random()}`}
                            id={`newPassword_${Math.random()}`}
                            autoComplete="off"
                        />
                    </div>
                    <div className="input-row confirm-pass">
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            name={`confirmPassword_${Math.random()}`}
                            id={`confirmPassword_${Math.random()}`}
                            autoComplete="off"
                        />
                    </div>
                </>
            )}
            <div className="agree-text">
                <input
                    type="checkbox"
                    checked={agreeTerms}
                    onChange={handleCheckboxChange}
                    name={`agree_${Math.random()}`}
                    id={`agree_${Math.random()}`}
                />
                <label htmlFor={`agree_${Math.random()}`}>
                    By clicking Create account, I agree that I have read and accepted the{' '}
                    <a href="#">Terms of use</a> and <a href="#">Privacy Policy</a>
                </label>
            </div>
            <div className="input-submit">
                <input
                    type="submit"
                    value={loading ? 'Signing up...' : 'Sign Up'}
                    disabled={loading || !agreeTerms}
                />
            </div>
            {error && <div className="error-message">{error}</div>}
        </form>
    </div>
);

export default SignupForm;
