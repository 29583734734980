import React, { createContext, useState } from "react";

const UtilsContext = createContext();

export const UtilsProvider = ({ children }) => {
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const [priceOption, setPriceOption] = useState(
    sessionStorage.getItem("priceOption") || ""
  );
  const [sortOption, setSortOption] = useState("");

  return (
    <UtilsContext.Provider
      value={{
        selectedSize,
        setSelectedSize,
        selectedColors,
        setSelectedColors,
        selectedPriceRange,
        setSelectedPriceRange,
        priceOption,
        setPriceOption,
        activeCategoryId,
        setActiveCategoryId,
        sortOption,
        setSortOption,
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

export const useAuth = () => React.useContext(UtilsContext);

export default UtilsContext;
