// Tabs.js
import React from 'react';

const Tabs = ({ activeTab, handleTabSwitch }) => (
    <div className="tabs">
        <ul id="tabs-nav">
            <li className={activeTab === 'login' ? 'active' : ''}>
                <button onClick={() => handleTabSwitch('login')}>Sign in</button>
            </li>
            <li className={activeTab === 'signup' ? 'active' : ''}>
                <button onClick={() => handleTabSwitch('signup')}>Sign Up</button>
            </li>
        </ul>
    </div>
);

export default Tabs;
