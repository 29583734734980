import React from "react";
import { Link } from "react-router-dom";
import "./LoginPrompt.css";

const LoginPrompt = ({ onClose }) => {
  const handleLoginClick = () => {
    onClose();
  };

  return (
    <div className="custom-popup-overlay">
      <div className="custom-popup">
        <p>Please Sign Up to continue.</p>
        <div className="popup-actions">
          <button onClick={onClose} className="login-button">
            Close
          </button>

          <Link to="/login" onClick={handleLoginClick} className="login-button">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPrompt;
