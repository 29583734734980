// Modal.js
import React from "react";
import "./StripeModal.css";
const StripeModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="StripeModal-overlay modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default StripeModal;
