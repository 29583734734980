import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Chat from './Chat';

const Tab3 = () => (
    <div id="tab-3">
        <nav className="tab-nav secondcall">
            <div className="tab active">
                <div className="top-block">
                    <div className="message-div">
                        <ul>
                            <li className="particip">
                                <a href="#"><span className="icon"><img src="/images/close_small.png" alt="" /></span> Add Participants </a>
                            </li>
                        </ul>
                        <div className="add">
                            <a href="#"><img src="/images/add.png" alt="" /></a>
                        </div>
                    </div>
                    <form method="post">
                        <input type="text" className="textbox" placeholder="Search" />
                    </form>
                </div>
                <ul id="call-user-list">
                    <li>
                        <Link to="#">
                            <span className="remove">X</span>
                            <span className="img"><img src="/images/ceylan.png" alt="" /></span>
                            <h5>Laverty</h5>
                        </Link>
                    </li>
                    {/* More users here */}
                </ul>
            </div>
        </nav>
        <Outlet />
    </div>
);

export default Tab3;
