import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import { useCart } from "../../context/CartContext";
import StripeModal from "./StripeModal";
import { GetCountries } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";

const cardIcons = {
  visa: "/images/card-icons/visa.svg",
  mastercard: "/images/card-icons/mastercard.svg",
  amex: "/images/card-icons/amex.svg",
  discover: "/images/card-icons/discover.svg",
  unionpay: "/images/card-icons/unionpay.svg",
};

const StripePaymentForm = ({
  clientSecret,
  apiToken,
  couponOwnerId,
  gateway,
  orderNum,
  shipmentRateObj,
  isOpen,
  onClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardType, setCardType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [cardNumberValue, setCardNumberValue] = useState("");
  const navigate = useNavigate();
  const { clearCart } = useCart();
  const { logout } = useAuth();
  const [countriesListStripe, setCountriesListStripe] = useState([]);
  const [countryCodeStripe, setCountryCodeStripe] = useState("");
  const [countryNameStripe, setCountryNameStripe] = useState("");

  useEffect(() => {
    const fetchCountries = async () => {
      const result = await GetCountries();
      setCountriesListStripe(result);
    };

    fetchCountries();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setError("Stripe.js has not yet loaded. Please try again later.");
      setLoading(false);
      return;
    }

    const isValidPostal = postcodeValidator(postalCode, countryCodeStripe); // Validate based on country code
    if (!isValidPostal) {
      setError(`Invalid postal code for ${countryNameStripe}`);
      setLoading(false);
      return;
    } else {
      setError(null);
    }

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: nameOnCard,
              address: {
                postal_code: postalCode,
                country: countryCodeStripe,
              },
            },
          },
        }
      );

      if (error) {
        setError(`Payment failed: ${error.message}`);
        navigate("/payment-failed", { state: { error: error.message } });
      } else if (paymentIntent) {
        switch (paymentIntent.status) {
          case "succeeded":
            setSucceeded(true);
            const response = await api.post("/payment/confirm_payment", {
              api_token: apiToken,
              coupon_owner_id: couponOwnerId.toString(),
              gateway: gateway || "Stripe",
              order_num: orderNum,
              payment_id: paymentIntent.id,
              shipment_rate_obj: shipmentRateObj,
            });

            if (response.data.status === 200) {
              clearCart();
              const cartResponse = await api.post("/cart/create_cart", {
                api_token: apiToken,
              });
              const { cart_id, order_num, item_count } =
                cartResponse.data.cart_data;
              localStorage.setItem("cart_id", cart_id);
              localStorage.setItem("order_num", order_num);
              localStorage.setItem("item_count", item_count);
              localStorage.setItem("cartCount", item_count);

              const userRole = localStorage.getItem("userRole");
              if (userRole === "shopper") {
                navigate(`/payment-details/${response.data.order_id}`, {
                  state: { paymentIntent },
                });
              } else {
                navigate("/home");
                return;
              }
            } else if (response.data.status === 401) {
              logout();
              navigate("/home");
              return;
            } else {
              setError("Payment confirmation failed.");
            }
            break;

          case "processing":
            setError(
              "Your payment is processing. You will be notified once it is confirmed."
            );
            break;

          case "requires_payment_method":
            setError("Payment failed. Please try another payment method.");
            navigate("/payment-failed", {
              state: { error: "Requires new payment method" },
            });
            break;

          default:
            setError("Something went wrong. Please try again.");
            navigate("/payment-failed", {
              state: { error: "Unknown error occurred" },
            });
            break;
        }
      }
    } catch (error) {
      setError(`An unexpected error occurred: ${error.message}`);
      navigate("/payment-failed", { state: { error: error.message } });
    }

    setLoading(false);
  };

  const handleCountryChangeStripe = (country) => {
    if (country) {
      setCountryCodeStripe(country.iso2);
      setCountryNameStripe(country.name);
    }
  };

  return (
    <StripeModal isOpen={isOpen} onClose={onClose} className="payment-modal">
      <form onSubmit={handleSubmit} className="payment-form">
        <h2>Add your payment information</h2>

        <div className="card-info">
          <label htmlFor="card-info">Card Details</label>
          <input
            id="name-on-card"
            type="text"
            value={nameOnCard}
            onChange={(e) => setNameOnCard(e.target.value)}
            placeholder="Name on Card"
            required
          />

          <div className="form-field">
            <CardNumberElement
              id="card-number"
              className="card-number"
              options={{
                placeholder: "Card Number",
                style: {
                  base: {
                    color: "#FFFFFF",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "14px",
                    "::placeholder": {
                      color: "#D3D3D3",
                      content: "Card Number",
                    },
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                },
              }}
            />
          </div>

          <div className="form-field cvv-exp">
            <CardExpiryElement
              id="expiry-date"
              options={{
                style: {
                  base: {
                    color: "#FFFFFF",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "14px",
                    "::placeholder": {
                      color: "#D3D3D3",
                    },
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                },
              }}
            />
            <CardCvcElement
              id="cvc"
              options={{
                style: {
                  base: {
                    color: "#FFFFFF",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "14px",
                    "::placeholder": {
                      color: "#D3D3D3",
                    },
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                },
              }}
            />
          </div>
        </div>

        <div className="form-field">
          <label htmlFor="postal-code">Billing Address</label>
          <select
            onChange={(e) => {
              const selectedIndex = e.target.selectedIndex;
              const selectedValue = e.target.value;
              if (selectedValue === "") {
                handleCountryChangeStripe(null);
              } else {
                handleCountryChangeStripe(
                  countriesListStripe[selectedIndex - 1]
                );
              }
            }}
            defaultValue={countryCodeStripe}
            className="custom-select"
          >
            <option value="" className="first-option">
              Select Country
            </option>
            {countriesListStripe.map((item, index) => (
              <option key={index} value={item.iso2} className="other-options">
                {item.name}
              </option>
            ))}
          </select>

          <input
            id="postal-code"
            type="text"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder="Postal Code"
          />
        </div>

        <button type="submit" disabled={!stripe || loading}>
          {loading ? "Processing..." : "Pay Now"}
        </button>
        {error && <div className="error-message">{error}</div>}
        {succeeded && <div className="success-message">Payment succeeded!</div>}
      </form>
    </StripeModal>
  );
};

export default StripePaymentForm;
