import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Chat from './Chat';

const Tab2 = () => (
    <div id="tab-2">
        <nav className="tab-nav secondcall">
            <div className="tab active">
                <div className="top-block">
                    <div className="message-div">
                        <ul>
                            <li>
                                <Link to="call-log">Call Log</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul id="call-log-list">
                    <li>
                        <div className="call-notification"><img src="/images/incoming.png" alt="" /></div>
                        <div className="call-from"><img src="/images/ceylan.png" alt="" /></div>
                        <div className="call-details">
                            <div className="top">
                                <h4 className="name">Ceylan</h4>
                                <span className="date-time">2024/08/01 14:03pm</span>
                            </div>
                            <div className="call-summary">
                                <span className="minutes">Ended . 6s</span>
                                <ul className="dial">
                                    <li><a href="#"><img src="/images/call-log-icon1.png" alt="" /></a></li>
                                    <li><a href="#"><img src="/images/call-log-icon2.png" alt="" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    {/* More call logs here */}
                </ul>
            </div>
        </nav>
        <Outlet />
    </div>
);

export default Tab2;
