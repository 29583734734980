import React from "react";
import "./Pagination.css";
const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const createPageNumbers = () => {
    const pageNumbers = [];
    const delta = 2;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - delta && i <= currentPage + delta)
      ) {
        pageNumbers.push(i);
      } else if (
        i === currentPage - delta - 1 ||
        i === currentPage + delta + 1
      ) {
        pageNumbers.push("...");
      }
    }

    return pageNumbers;
  };

  const pageNumbers = createPageNumbers();

  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => handlePageClick(currentPage - 1)}
            disabled={currentPage === 1}
          >
            « Previous
          </button>
        </li>
        {pageNumbers.map((pageNumber, index) => (
          <li
            key={index}
            className={`page-item ${pageNumber === "..." ? "disabled" : ""} ${
              pageNumber === currentPage ? "active" : ""
            }`}
          >
            {pageNumber === "..." ? (
              <span className="page-link">...</span>
            ) : (
              <button
                className="page-link"
                onClick={() => handlePageClick(pageNumber)}
              >
                {pageNumber}
              </button>
            )}
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => handlePageClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next »
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
