// src/api/axiosInstance.js

import axios from 'axios';

// Create an axios instance with default configuration
const axiosInstance = axios.create({
    baseURL: 'https://global.seashels.com:7777/api', // Set your base URL here
    headers: {
        'Content-Type': 'application/json',
        // Add any other default headers here if needed
    },
});

// Request interceptor to add the Bearer token to headers
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('apiToken');
        console.log('token', token)
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default axiosInstance;
