import React, { useState, useEffect, useCallback } from "react";
import UserLocationContext from "./UserLocationContext";
import CountryCodeString from "../../components/currencySelector/countryCodes.json";

const UserLocationProvider = ({ children }) => {
  // Default US coordinates (Washington, D.C.)
  const DEFAULT_LOCATION = {
    latitude: 38.8954,
    longitude: -77.0365,
    country: "US",
    countryCode: "US",
    countryName: "United States",
  };

  // Function to get dialing code by country code
  const getDialingCodeByCountryCode = (countryCode) => {
    // Iterate through the keys of the object
    for (const dialCode in CountryCodeString) {
      if (CountryCodeString[dialCode] === countryCode.toUpperCase()) {
        return dialCode; // Found the matching dial code
      }
    }
    return 1; // Return null if no match is found
  };

  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch country info using OpenStreetMap (Nominatim)
  const fetchCountryInfo = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data, "data");
      if (data && data.address) {
        const country = data.address.country;
        const countryCode = data.address.country_code.toUpperCase();
        const countryName = data.address.country;
        const countryData = data.address;

        const dialingCode = getDialingCodeByCountryCode(countryCode);

        return { country, countryCode, countryName, data, dialingCode };
      }
    } catch (error) {
      console.error("Error fetching country info:", error);
      setError("Failed to fetch country info.");
    }
    return null;
  };

  // Function to get current location
  const getCurrentLocation = useCallback(() => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
      setLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const newLatitude = position.coords.latitude;
        const newLongitude = position.coords.longitude;

        // Fetch country info based on lat and long
        const countryInfo = await fetchCountryInfo(newLatitude, newLongitude);

        // Update location state with the fetched data
        setLocation({
          latitude: newLatitude,
          longitude: newLongitude,
          country: countryInfo ? countryInfo.country : null,
          countryCode: countryInfo ? countryInfo.countryCode : null,
          countryName: countryInfo ? countryInfo.countryName : null,
          countryInfo: countryInfo ? countryInfo.data : null,
          dialCode: countryInfo ? countryInfo.dialingCode : "1",
        });

        setLoading(false);
        setError(null);
      },
      (err) => {
        console.error("Location error:", err);
        setError("Location access denied. Defaulting to US address.");
        setLocation(DEFAULT_LOCATION); // Set default location
        setLoading(false);
      },
      {
        enableHighAccuracy: true,
        timeout: 10000, // Timeout after 10 seconds
        maximumAge: 0,
      }
    );
  }, []);

  // Fetch location on component mount
  useEffect(() => {
    getCurrentLocation();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <UserLocationContext.Provider
      value={{
        location,
        loading,
        error,
      }}
    >
      {children}
    </UserLocationContext.Provider>
  );
};

export default UserLocationProvider;
