import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import ShopTabFilter from "./ShopTabFilter";
import SubCategoryList from "./SubCategoryList";
import ShopFilterProductListing from "./ShopFilterProductListing";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UtilsContext from "../../context/UtilsContext";

const ShopFilter = ({
  loading,
  productdetails,
  SubCategoryList_data,
  storeUuid,
  isLoading,
}) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();

  const previousParams = useRef({
    category_id: null,
    gender_id: null,
    segment_id: null,
    sub_category_id: null,
  });

  const [tab, setTab] = useState("all");
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(true);
  const [filterOptions, setFilterOptions] = useState({
    sizeOptions: [],
    colorOptions: [],
    sortOptions: [
      { value: "ASC", label: "Price Low To High" },
      { value: "DESC", label: "Price High To Low" },
    ],
  });

  const [currencyCode, setCurrencyCode] = useState(
    localStorage.getItem("currencyCode") || ""
  );
  const [sortOption, setSortOption] = useState(
    sessionStorage.getItem("sortOption") || ""
  );
  const [sizeOption, setSizeOption] = useState(
    JSON.parse(sessionStorage.getItem("sizeOption")) || []
  );
  const [colorOption, setColorOption] = useState(
    JSON.parse(sessionStorage.getItem("colorOption")) || []
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [productData, setProductData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const { priceOption, setPriceOption } = useContext(UtilsContext);
  const saveFiltersToSession = useCallback(() => {
    sessionStorage.setItem("priceOption", priceOption);
    sessionStorage.setItem("sortOption", sortOption);
    sessionStorage.setItem("sizeOption", JSON.stringify(sizeOption));
    sessionStorage.setItem("colorOption", JSON.stringify(colorOption));

    sessionStorage.setItem("category_id", category_id);
    sessionStorage.setItem(
      "category_name",
      localStorage.getItem("category_name") || ""
    );
    sessionStorage.setItem(
      "sub_category_id",
      sub_category_id || selectedSubCategory || ""
    );
    sessionStorage.setItem(
      "subcategory_name",
      localStorage.getItem("subcategory_name") || ""
    );
  }, [
    category_id,
    sub_category_id,
    selectedSubCategory,
    sortOption,
    sizeOption,
    priceOption,
    colorOption,
  ]);

  const clearFiltersFromSession = () => {
    sessionStorage.removeItem("sortOption");
    sessionStorage.removeItem("sizeOption");
    sessionStorage.removeItem("priceOption");
    sessionStorage.removeItem("colorOption");
    sessionStorage.removeItem("category_id");
    sessionStorage.removeItem("category_name");
    sessionStorage.removeItem("sub_category_id");
    sessionStorage.removeItem("subcategory_name");
  };

  useEffect(() => {
    const areParamsSame =
      previousParams.current.category_id === category_id &&
      previousParams.current.gender_id === gender_id &&
      previousParams.current.segment_id === segment_id &&
      previousParams.current.sub_category_id === sub_category_id;

    const fetchData = async () => {
      if (initialLoad) setIsLoadingData(true);

      try {
        const api_token = localStorage.getItem("apiToken");
        if (!api_token) throw new Error("API token is missing");

        const formattedSize = sizeOption.length > 0 ? sizeOption.join(",") : "";
        const formattedColor =
          colorOption.length > 0 ? colorOption.join(",") : "";
        const [minPrice, maxPrice] =
          priceOption &&
          typeof priceOption === "string" &&
          priceOption.includes("-")
            ? priceOption.split("-")
            : ["", ""];

        const sortby =
          sortOption && typeof sortOption === "string" && sortOption
            ? sortOption
            : "";

        const response = await api.get("/filters/get_skus_for_filter", {
          params: {
            api_token,
            page: 0,
            store_uuid: storeUuid,
            segment_id,
            gender_id,
            category_list: category_id,
            currency_code: currencyCode,
            sort_order: sortby,
            size_list: formattedSize,
            color_list: formattedColor,
            price_min: minPrice,
            price_max: maxPrice,

            sub_category_list: sub_category_id || selectedSubCategory || "",
          },
        });

        if (response.status === 401) {
          logout();
          navigate("/home");
          return;
        }

        if (response.status === 200) {
          const responseData = response.data;
          if (!responseData) throw new Error("Response data is missing");
          setProductData(responseData);

          const colorListResponse = await api.get("/filters/get_colors_list", {
            params: {
              api_token,
              gender_id,
              segment_id,
              categories_list: category_id,
              currency_code: currencyCode,
              store_uuid: storeUuid,
              sub_categories_list: sub_category_id || selectedSubCategory || "",
              price_min: minPrice,
              price_max: maxPrice,
            },
          });

          const sizeListResponse = await api.get("/filters/get_sizes_list", {
            params: {
              api_token,
              gender_id,
              segment_id,
              categories_list: category_id,
              store_uuid: storeUuid,
              currency_code: currencyCode,
              sub_categories_list: sub_category_id || selectedSubCategory || "",
              price_min: minPrice,
              price_max: maxPrice,
            },
          });

          const colorListData = colorListResponse.data?.colors_data || [];
          const sizeListData = sizeListResponse.data?.sizes_data || [];
          const priceListData = responseData.price_band || [];

          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            sizeOptions: sizeListData,
            colorOptions: colorListData,
          }));

          setShowAdditionalFilters(true);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingData(false);
        setInitialLoad(false);
      }
    };

    fetchData();
    previousParams.current = {
      category_id,
      gender_id,
      segment_id,
      sub_category_id,
    };
  }, [
    category_id,
    gender_id,
    segment_id,
    sub_category_id,
    storeUuid,
    currencyCode,
    sortOption,
    sizeOption,
    priceOption,
    colorOption,
    logout,
    navigate,
    initialLoad,
  ]);

  const handleSortChange = useCallback(
    (e) => {
      setSortOption(e);
      saveFiltersToSession();
      setIsLoadingData(true);
    },
    [saveFiltersToSession]
  );

  const handleSizeChange = useCallback(
    (e) => {
      setSizeOption(e);
      saveFiltersToSession();
      setIsLoadingData(true);
    },
    [saveFiltersToSession]
  );

  const handlePriceChange = useCallback((e) => {
    setPriceOption(e);
    saveFiltersToSession();
    setIsLoadingData(true);
  }, []);

  const handleColorChange = useCallback(
    (e) => {
      setColorOption(e);
      saveFiltersToSession();
      setIsLoadingData(true);
    },
    [saveFiltersToSession]
  );

  const handleClearFilters = useCallback(() => {
    setPriceOption("");
    setSortOption("");
    setSizeOption([]);
    setColorOption([]);
    setSelectedSubCategory(null);
    setProductData(null);
    clearFiltersFromSession();

    navigate(`/shop/${category_id}/${gender_id}/${segment_id}`);
    setIsLoadingData(true);
  }, [category_id, gender_id, segment_id, navigate]);

  const handleSubCategoryChange = useCallback(
    (subCategoryId, subCategoryName, categoryName) => {
      setPriceOption("");
      setSortOption("");
      setSizeOption([]);
      setColorOption([]);
      setSelectedSubCategory(subCategoryId);

      localStorage.setItem("category_name", categoryName);
      localStorage.setItem("subcategory_name", subCategoryName);

      saveFiltersToSession();
      setIsLoadingData(true);

      navigate(
        `/shop/${category_id}/${gender_id}/${segment_id}/${subCategoryId}`,
        {
          state: {
            categoryName: categoryName,
            subCategoryName: subCategoryName,
            subCategoryId: subCategoryId,
            categoryId: category_id,
          },
        }
      );
    },
    [category_id, gender_id, segment_id, navigate, saveFiltersToSession]
  );

  const handleSizeClearFilter = useCallback(() => {
    setSizeOption([]);
    setShowAdditionalFilters(true);
    saveFiltersToSession();
  }, [saveFiltersToSession]);

  const handleColorClearFilter = useCallback(() => {
    setColorOption([]);
    setShowAdditionalFilters(true);
    saveFiltersToSession();
  }, [saveFiltersToSession]);

  const handlePriceClearFilter = useCallback(() => {
    setPriceOption("");
    setShowAdditionalFilters(true);
    saveFiltersToSession();
  }, [saveFiltersToSession]);

  const handleSortClearFilter = useCallback(() => {
    setSortOption("");
    setShowAdditionalFilters(true);
    saveFiltersToSession();
  }, [saveFiltersToSession]);

  useEffect(() => {
    saveFiltersToSession();
  }, [
    priceOption,
    sizeOption,
    sortOption,
    colorOption,
    category_id,
    sub_category_id,
    selectedSubCategory,
    saveFiltersToSession,
  ]);
  const totalCount =
    productData &&
    typeof productData === "object" &&
    "total_count" in productData
      ? productData.total_count
      : "0";

  return (
    <div className="store-filter">
      <div className="container">
        <div className="parent-shop-container">
          <div className="left-sidebar">
            <SubCategoryList
              categoryData={SubCategoryList_data}
              onSubCategoryChange={handleSubCategoryChange}
              onClearFilters={handleClearFilters}
              subCategoryStatus={sub_category_id}
              isLoading={isLoading}
            />
          </div>

          <div className="store-filter_right">
            <ShopTabFilter
              sortOptions={filterOptions.sortOptions}
              sizeOptions={filterOptions.sizeOptions}
              colorOptions={filterOptions.colorOptions}
              onSortChange={handleSortChange}
              onSizeChange={handleSizeChange}
              onPriceChange={handlePriceChange}
              onColorChange={handleColorChange}
              onClearFilters={handleClearFilters}
              onPriceClearFilter={handlePriceClearFilter}
              onSizeClearFilter={handleSizeClearFilter}
              onColorClearFilter={handleColorClearFilter}
              sortOption={sortOption}
              sizeOption={sizeOption}
              colorOption={colorOption}
              totalCount={totalCount}
              showAdditionalFilters={showAdditionalFilters}
              loading={initialLoad ? isLoadingData : false}
            />
            <div id="filter-products">
              <div className="container">
                {isLoadingData ? (
                  <div className="also-like latest uniquelist">
                    <ul className="skeleton-list">
                      {Array.from({ length: 8 }).map((_, index) => (
                        <li key={index}>
                          <Skeleton height={400} width={261.34} />
                          <h4>
                            <Skeleton width={200} />
                          </h4>
                          <div className="price-value">
                            <Skeleton width={100} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  productData && (
                    <ShopFilterProductListing
                      storeUuid={storeUuid}
                      sortOption={sortOption}
                      sizeOption={sizeOption}
                      priceOption={priceOption}
                      colorOption={colorOption}
                      segmentId={segment_id}
                      genderId={gender_id}
                      categoryList={category_id}
                      sub_category_list={sub_category_id}
                      productData={productData}
                      onClearFilters={handleClearFilters}
                      loading={isLoadingData}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopFilter;
