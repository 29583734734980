import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import api from "../../api";
import "./NavMenu.css";
const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setDeviceType("mobile");
      } else if (window.matchMedia("(max-width: 1024px)").matches) {
        setDeviceType("desktop");
      } else {
        setDeviceType("desktop");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return deviceType;
};
const NavMenu = () => {
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();
  const [menuData, setMenuData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState("");
  const [activeSubCategory, setActiveSubCategory] = useState("");
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [showShopMenu, setShowShopMenu] = useState(false);
  const [showStylesMenu, setShowStylesMenu] = useState(false);
  const location = useLocation();
  const megaMenuRef = useRef(null);
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const previousParams = useRef({
    category_id: null,
    gender_id: null,
    segment_id: null,
    sub_category_id: null,
  });

  const [previousClick, setPreviousClick] = useState(null);
  useEffect(() => {
    const storedCategory = localStorage.getItem("activeCategory");
    const storedSubCategory = localStorage.getItem("activeSubCategory");
    // console.log(storedCategory, "storedCategory");
    // console.log(storedSubCategory, "storedSubCategory");
    if (storedCategory && !category_id) {
      setActiveCategory(storedCategory);
    }

    if (storedSubCategory && !sub_category_id) {
      setActiveSubCategory(storedSubCategory);
    }
  }, []);

  useEffect(() => {
    const api_token = localStorage.getItem("apiToken");
    const fetchCategories = async () => {
      try {
        const response = await api.get("/website/get_nav_menu", {
          params: {
            api_token,
            gender_id: 2,
            segment_id: 1,
            menu_order: 6,
          },
        });

        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        setMenuData(response.data.data || {});
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (api_token != null) {
      fetchCategories();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (megaMenuRef.current && !megaMenuRef.current.contains(event.target)) {
        setHoveredCategory(null);
        setShowShopMenu(false);
        setShowStylesMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleMouseLeaveCategory = () => {
    setHoveredCategory(null);
  };

  const handleMouseEnterShop = () => {
    setShowStylesMenu(false);
    setShowShopMenu(true);
  };

  const handleMouseEnterStyles = () => {
    setShowShopMenu(false);
    setShowStylesMenu(true);
  };

  const handleMouseLeaveMenus = () => {
    setShowShopMenu(false);
    setShowStylesMenu(false);
  };
  const handleNavigationShop = (
    mainItem,
    subCategoryId,
    path,
    categoryName,
    subCategoryName
  ) => {
    setShowStylesMenu(false);

    if (deviceType === "mobile" && mainItem === "Shop") {
      if (subCategoryId != null && Number.isInteger(subCategoryId)) {
        const button = document.getElementById("clearButton");
        if (button) {
          button.click();
        }
        const buttonclose = document.getElementById("nav-icon2");
        if (buttonclose) {
          buttonclose.click();
        }
        setShowShopMenu(true);
        setActiveCategory(mainItem);
        setHoveredCategory(mainItem);
        navigate(path, {
          replace: true,
          state: { mainItem, subCategoryId, mainItem, subCategoryName },
        });
        setShowShopMenu(true);

        return;
      } else {
        console.log("showShopMenu", showShopMenu);
        console.log("mainitem", mainItem, activeCategory);
        if (mainItem === activeCategory) {
          console.log("showShopMenu show");
          setShowShopMenu(false);
          setActiveCategory("");
          setActiveSubCategory("");
          setHoveredCategory("");
        } else {
          console.log("showShopMenu hide");
          setShowShopMenu(true);
          setActiveCategory(mainItem);
          setHoveredCategory(mainItem);
        }
      }
    }
  };

  const handleNavigationStyles = (
    mainItem,
    subCategoryId,
    path,
    categoryName,
    subCategoryName
  ) => {
    console.log(showShopMenu, "show");
    console.log(subCategoryId, "subCategoryId");
    console.log(subCategoryId, "subCategoryId");

    // if (typeof subCategoryId === "number" && Number.isInteger(subCategoryId)) {
    //   console.log("subCategoryId is an integer");
    // } else if (typeof subCategoryId === "string") {
    //   console.log("subCategoryId is a string");
    // } else {
    //   console.log("subCategoryId is of a different type");
    // }

    setShowShopMenu(false);
    if (deviceType === "mobile" && mainItem === "Styles") {
      if (subCategoryId != null && subCategoryId) {
        // Case: Mobile device, "Shop" main item, and sub-category exists as an integer
        const button = document.getElementById("clearButton");
        if (button) {
          button.click();
        }
        const buttonclose = document.getElementById("nav-icon2");
        if (buttonclose) {
          buttonclose.click();
        }
        setShowStylesMenu(true);

        setActiveCategory(mainItem);
        setHoveredCategory(mainItem);
        navigate(path, {
          replace: true,
          state: { mainItem, subCategoryId, mainItem, subCategoryName },
        });
        return;
      } else {
        if (mainItem === activeCategory) {
          setShowStylesMenu(false);

          setActiveCategory("");
          setActiveSubCategory("");
          setHoveredCategory("");
        } else {
          setShowStylesMenu(true);
          setActiveCategory(mainItem);
          setHoveredCategory(mainItem);
        }
      }
    }
  };
  const handleNavigation = (
    mainItem,
    subCategoryId,
    path,
    categoryName,
    subCategoryName
  ) => {
    if ((mainItem === "Shop" || mainItem === "Styles") && !subCategoryId) {
      setActiveCategory(mainItem);
      return;
    }

    const button = document.getElementById("clearButton");
    if (button) {
      button.click();
    }
    const buttonclose = document.getElementById("nav-icon2");
    if (buttonclose) {
      buttonclose.click();
    }
    setHoveredCategory(null);

    setActiveCategory(mainItem);
    setActiveSubCategory(subCategoryId);
    localStorage.setItem("activeCategory", mainItem);
    localStorage.setItem("activeSubCategory", subCategoryId);
    navigate(path, {
      replace: true,
      state: { mainItem, subCategoryId, categoryName, subCategoryName },
    });
  };

  useEffect(() => {
    if (category_id) {
      setActiveCategory(category_id);
      localStorage.setItem("activeCategory", category_id);
    }
    if (sub_category_id) {
      setActiveSubCategory(sub_category_id);
      localStorage.setItem("activeSubCategory", sub_category_id);
    }
  }, [category_id, sub_category_id]);

  useEffect(() => {
    const validRoutes = [
      "/shop",
      "/latest-arrivals",
      "/deals",
      "/stores",
      "/visit-store",
    ];

    const isValidRoute = validRoutes.some((route) =>
      location.pathname.includes(route)
    );

    if (!isValidRoute) {
      setActiveCategory("");
      setActiveSubCategory("");
    } else {
      if (location.pathname.includes("/visit-store")) {
        setActiveCategory("Stores");
        setActiveSubCategory("");
      } else {
        const { categoryName, subCategoryName, subCategoryId, categoryId } =
          location.state || {};

        const categoryIdToUse = Array.isArray(categoryId)
          ? categoryId[0]
          : categoryId;
        const subCategoryIdToUse = Array.isArray(subCategoryId)
          ? subCategoryId[0]
          : subCategoryId;

        const sub_id = sessionStorage.getItem("sub_category_id");
        const category_id = sessionStorage.getItem("category_id");

        const megamenuElements = document.getElementsByClassName("megamenu");

        const hasActiveClass = Array.from(megamenuElements).some((element) =>
          element.classList.contains("active")
        );

        if (deviceType === "mobile" && subCategoryName) {
          const subCategoryElement = document.querySelector(
            `.megamenu li[data-subcategory="${subCategoryName}"]`
          );
          if (subCategoryElement) {
            subCategoryElement.classList.add("highlight");
            subCategoryElement.scrollIntoView({ behavior: "smooth" });
          }
        }

        setActiveCategory(
          Array.isArray(categoryName)
            ? categoryName[0]
            : categoryName ||
                localStorage.getItem("activeCategory") ||
                category_id ||
                categoryIdToUse
        );

        setActiveSubCategory(subCategoryIdToUse || sub_id);

        if (!isValidRoute && categoryName) {
          setActiveCategory("Shop");
          setActiveSubCategory(subCategoryIdToUse);
        }
      }
    }
  }, [location.pathname]);

  const handleToggleCategory = (
    mainItem,
    subCategoryId,
    path,
    categoryName,
    subCategoryName
  ) => {
    if (deviceType === "mobile") {
      console.log("activeCategory", activeCategory);
      if (mainItem === activeCategory) {
        setActiveCategory("");
        setActiveSubCategory("");
        setHoveredCategory("");
      } else {
        setHoveredCategory(mainItem);
        setActiveCategory(mainItem);
        setActiveSubCategory(subCategoryId);
      }
    }
  };

  const handleMouseEnterCategory = (mainItem) => {
    if (
      deviceType === "desktop" &&
      mainItem !== "Shop" &&
      mainItem !== "Styles"
    ) {
      setHoveredCategory(mainItem);
    }
  };
  return (
    <nav className="sidebar-menu" ref={megaMenuRef}>
      <ul className="nav-menu">
        {Object.keys(menuData)
          .sort((a, b) => menuData[a].menu_order - menuData[b].menu_order)
          .map((mainItem, index) => {
            const item = menuData[mainItem];
            const isCategoryActive = mainItem === activeCategory;
            const isShopActive = mainItem === "Shop" && showShopMenu;
            const isStylesActive = mainItem === "Styles" && showStylesMenu;

            return (
              <li
                key={`${item.id}-${index}`}
                className={`menuItemClass ${mainItem} ${
                  isCategoryActive ? "active" : ""
                }`}
              >
                <a
                  onClick={() => {
                    if (deviceType === "mobile") {
                      if (
                        mainItem !== "Shop" &&
                        mainItem !== "Styles" &&
                        mainItem !== "Stores" &&
                        item.type !== "deal" &&
                        item.type !== "storelisting"
                      ) {
                        handleToggleCategory(mainItem);
                      } else if (mainItem === "Shop") {
                        handleNavigationShop(
                          mainItem,
                          "",
                          item.type === "category"
                            ? `/shop/${item.id}/${item.gender_id}/${item.segment_id}`
                            : item.type === "deal"
                            ? "/deals"
                            : item.type === "livesocial"
                            ? "/live-social"
                            : item.type === "styles"
                            ? "#"
                            : item.type === "storelisting"
                            ? "/stores"
                            : "/",
                          mainItem,
                          ""
                        );
                      } else if (mainItem === "Styles") {
                        handleNavigationStyles(
                          mainItem,
                          "",
                          item.type === "category"
                            ? `/shop/${item.id}/${item.gender_id}/${item.segment_id}`
                            : item.type === "deal"
                            ? "/deals"
                            : item.type === "livesocial"
                            ? "/live-social"
                            : item.type === "styles"
                            ? "#"
                            : item.type === "storelisting"
                            ? "/stores"
                            : "/",
                          mainItem,
                          ""
                        );
                      } else {
                        handleNavigation(
                          mainItem,
                          "",
                          item.type === "category"
                            ? `/shop/${item.id}/${item.gender_id}/${item.segment_id}`
                            : item.type === "deal"
                            ? "/deals"
                            : item.type === "livesocial"
                            ? "/live-social"
                            : item.type === "styles"
                            ? "#"
                            : item.type === "storelisting"
                            ? "/stores"
                            : "/",
                          mainItem,
                          ""
                        );
                      }
                    } else {
                      handleNavigation(
                        mainItem,
                        "",
                        item.type === "category"
                          ? `/shop/${item.id}/${item.gender_id}/${item.segment_id}`
                          : item.type === "deal"
                          ? "/deals"
                          : item.type === "livesocial"
                          ? "/live-social"
                          : item.type === "styles"
                          ? "#"
                          : item.type === "storelisting"
                          ? "/stores"
                          : "/",
                        mainItem,
                        ""
                      );
                    }
                  }}
                  onMouseEnter={() =>
                    mainItem === "Shop"
                      ? handleMouseEnterShop()
                      : mainItem === "Styles"
                      ? handleMouseEnterStyles()
                      : handleMouseEnterCategory(mainItem)
                  }
                  className={
                    mainItem === "Shop" || mainItem === "Styles"
                      ? "disabled-link"
                      : ""
                  }
                >
                  {mainItem}
                  {!(
                    item.type === "storelisting" ||
                    item.type === "deal" ||
                    item.type === "livesocial"
                  ) && (
                    <span className="arrow">
                      {isCategoryActive ? (
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 17.586l-4.293-4.293 1.414-1.414L12 14.757l3.879-3.879 1.414 1.414z" />
                          </svg>
                        </span>
                      ) : (
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.293 16.293 12.586 12 8.293 7.707l1.414-1.414L15.414 12l-5.707 5.707z"></path>
                          </svg>
                        </span>
                      )}
                    </span>
                  )}
                </a>
                {/* Sub-categories for categories except "Shop" and "Styles" */}
                {(hoveredCategory === mainItem && item.sub_category_list) ||
                (deviceType === "mobile" &&
                  mainItem &&
                  isCategoryActive &&
                  mainItem != "Styles" &&
                  mainItem != "Shop" &&
                  item.sub_category_list) ? (
                  <div
                    className="mega-menu-trigger mega-menu"
                    onMouseEnter={() => setHoveredCategory(mainItem)}
                    onMouseLeave={handleMouseLeaveCategory}
                  >
                    <div ref={megaMenuRef} className="sub-category-menu">
                      <div className="container">
                        <div className="row">
                          {item.sub_category_list.map((subCategory, index) => (
                            <div
                              key={`${subCategory.sub_category_id}-${index}`}
                              className={`col-3 menuItemClassSub-data ${
                                activeSubCategory ===
                                subCategory.sub_category_id
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <a
                                onClick={() =>
                                  handleNavigation(
                                    mainItem,
                                    subCategory.sub_category_id,
                                    `/shop/${subCategory.category_id}/${subCategory.gender_id}/${subCategory.segment_id}/${subCategory.sub_category_id}`,
                                    mainItem,
                                    subCategory.sub_category_name
                                  )
                                }
                              >
                                {subCategory.sub_category_name}
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* Shop menu */}
                {/* 
                {console.log("details", isShopActive)}
                {console.log("activeCategory", activeCategory)}
                {console.log("showShopMenu", showShopMenu)} */}
                {/* {mainItem === "Shop" && isShopActive && ( */}
                {((mainItem === "Shop" && isShopActive) ||
                  (deviceType === "mobile" && activeCategory === "Shop")) && (
                  <>
                    <div
                      className="mega-menu-trigger mega-menu"
                      onMouseEnter={handleMouseEnterShop}
                      onMouseLeave={handleMouseLeaveMenus}
                    >
                      <div ref={megaMenuRef} className="sub-category-menu">
                        <div className="container">
                          <div className="row">
                            {item?.categories_list?.map((category) => (
                              <div
                                key={category.category_id}
                                className={`col-3 menuItemClassSub-data ${
                                  activeSubCategory === category.category_id
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <a
                                  onClick={() => {
                                    if (deviceType === "mobile") {
                                      handleNavigationShop(
                                        mainItem,
                                        category.category_id,
                                        `/shop/${category.category_id}/${category.gender_id}/${category.segment_id}`,
                                        category.name,
                                        ""
                                      );
                                    } else {
                                      handleNavigation(
                                        mainItem,
                                        category.category_id,
                                        `/shop/${category.category_id}/${category.gender_id}/${category.segment_id}`,
                                        category.name,
                                        ""
                                      );
                                    }
                                  }}
                                >
                                  {category.name}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Styles menu */}
                {((mainItem === "Styles" && isStylesActive) ||
                  (deviceType === "mobile" &&
                    activeCategory === "Styles" &&
                    isStylesActive &&
                    activeCategory != "Shop")) && (
                  <div
                    className="mega-menu-trigger mega-menu"
                    onMouseEnter={handleMouseEnterStyles}
                    onMouseLeave={handleMouseLeaveMenus}
                  >
                    <div ref={megaMenuRef} className="sub-category-menu">
                      <div className="container">
                        <div className="row">
                          {item?.sidebar?.map((sidebars) => (
                            <div
                              key={sidebars.tag_id}
                              className={`col-3 menuItemClassSub-data ${
                                activeSubCategory === sidebars.tag_id
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <a
                                onClick={() => {
                                  if (
                                    deviceType === "mobile" &&
                                    activeCategory === "Styles"
                                  ) {
                                    handleNavigationStyles(
                                      mainItem,
                                      sidebars.tag_id,
                                      `/latest-arrivals/${sidebars.tag_id}/${sidebars.type}`,
                                      "",
                                      ""
                                    );
                                  } else {
                                    handleNavigation(
                                      mainItem,
                                      sidebars.tag_id,
                                      `/latest-arrivals/${sidebars.tag_id}/${sidebars.type}`,
                                      "",
                                      ""
                                    );
                                  }
                                }}
                              >
                                {sidebars.name}
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default NavMenu;
