import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import ProductList from "./ProductList";
import LoadMore from "./LoadMore/LoadMore";
import { useAuth } from "../context/AuthContext";
import SortSelect from "./multiSelect/SortSelect";

const ITEMS_PER_PAGE = 54;

const LatestArrivalsMain = ({
  apiEndpoint = "/tags/get_sku_list_for_tag",
  pageTitle = "",
}) => {
  const { logout } = useAuth();

  const { tag_id, tag_type } = useParams();
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedItemCount, setLoadedItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const defaultImage = "https://via.placeholder.com/150";
  const navigate = useNavigate();
  const [selectedSort, setSelectedSort] = useState("");
  const [sortOption, setSortOption] = useState("");

  const [filterOptions, setFilterOptions] = useState({
    sizeOptions: [],
    priceOptions: [],
    colorOptions: [],
    sortOptions: [
      { value: "low", label: "Price Low To High" },
      { value: "high", label: "Price High To Low" },
    ],
  });
  const fetchProducts = async (append = false, currentSortOrder = "") => {
    try {
      if (!append) {
        setLoading(true);
        setLoadedItemCount(0);
      } else {
        setLoadingMore(true);
      }

      const api_token = localStorage.getItem("apiToken");
      const response = await api.get(apiEndpoint, {
        params: {
          api_token: api_token,
          tag_type: tag_type,
          tag_id: tag_id,
          page: Math.floor(loadedItemCount / ITEMS_PER_PAGE),
          per_page: ITEMS_PER_PAGE,
          sort: currentSortOrder,
        },
      });
      if (response.data.status === 401) {
        logout();
        navigate("/home");
      } else if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = response.data;
      const processedProducts = data.tags_data;

      setProducts((prevProducts) =>
        append ? [...prevProducts, ...processedProducts] : processedProducts
      );

      if (!append) {
        setOriginalProducts(processedProducts);
      }

      const newLoadedCount = append
        ? loadedItemCount + processedProducts.length
        : processedProducts.length;
      setLoadedItemCount(newLoadedCount);
      setTotalItems(data.tags_items_total_count);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchProducts(false, sortOrder);
  }, [apiEndpoint, tag_id, tag_type, sortOrder]);

  useEffect(() => {}, [loadedItemCount]);

  const handleProductClick = (sui) => {
    navigate(`/product/${sui}`);
  };

  const handleSortChange = (e) => {
    setSortOption(e);
    setSortOrder(e);

    setLoadedItemCount(0);
    setProducts([]);
    fetchProducts(false, e);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSortOrder("");
    setProducts(originalProducts);
    fetchProducts(false, "");
    setSelectedSort([]);
  };

  const loadMoreProducts = () => {
    fetchProducts(true, sortOrder);
  };

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="main-content">
      <div className="wear-offer latest-arrival">
        <div className="container">
          <h2>{pageTitle}</h2>
          <div className="sort-row">
            <div className="sort-by">
              <SortSelect
                onChange={(value) => {
                  setSelectedSort(value);
                  handleSortChange(value);
                }}
                data={filterOptions.sortOptions}
                selectedValue={selectedSort}
              />
            </div>
            <div className="clear">
              <a href="#" onClick={handleClearFilter}>
                CLEAR
              </a>
            </div>
          </div>

          <ProductList
            products={products}
            loading={loading}
            loadedItemCount={loadedItemCount}
            ITEMS_PER_PAGE={ITEMS_PER_PAGE}
            defaultImage={defaultImage}
            handleProductClick={handleProductClick}
          />

          <LoadMore
            loadedItemCount={loadedItemCount}
            totalItems={totalItems}
            onLoadMore={loadMoreProducts}
            loadingMore={loadingMore}
          />
        </div>
      </div>
    </div>
  );
};

export default LatestArrivalsMain;
