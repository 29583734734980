import React, { useState } from 'react';

const ITEMS_PER_PAGE = 10; // Adjust according to your needs

function PaginationComponent({ tags_item_count, tags_page_count, next_page, prev_page, tags_items_total_count }) {
    const [currentPage, setCurrentPage] = useState(1);

    // Update currentPage based on the next_page and prev_page props
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Determine if next/previous page is available
    const hasNextPage = next_page !== -1;
    const hasPrevPage = prev_page !== -1;

    return (
        <div>
            <div>
                {/* Render current page items (assuming you get them based on the currentPage) */}
                {/* Example items rendering (replace this with actual items data rendering) */}
                <p>Displaying items for page {currentPage}</p>

                {/* Pagination Controls */}
                <div>
                    <button
                        onClick={() => handlePageChange(prev_page)}
                        disabled={!hasPrevPage}
                    >
                        Previous
                    </button>

                    <span>Page {currentPage} of {tags_page_count}</span>

                    <button
                        onClick={() => handlePageChange(next_page)}
                        disabled={!hasNextPage}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

// Usage example
const exampleParams = {
    tags_item_count: 54,
    tags_page_count: 46, // Example calculated value
    next_page: 2,
    prev_page: 0,
    tags_items_total_count: 451
};

function App() {
    return (
        <div>
            <PaginationComponent
                tags_item_count={exampleParams.tags_item_count}
                tags_page_count={exampleParams.tags_page_count}
                next_page={exampleParams.next_page}
                prev_page={exampleParams.prev_page}
                tags_items_total_count={exampleParams.tags_items_total_count}
            />
        </div>
    );
}

export default App;
