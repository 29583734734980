// src/components/SkeletonLoader.js
import React from "react";
import "./SkeletonCart.css";
const SkeletonCart = ({ itemCount }) => {
  return (
    <div className="skeleton-loader">
      {/* Cart Item Skeletons */}
      <div className="new-orderrow">
        <div className="order-details">
          <div className="store-order">
            <div className="store-section">
              {[...Array(itemCount)].map((_, index) => (
                <div key={index}>
                  <div className="skeleton-header"></div>
                  <div className="skeleton-item">
                    <div className="skeleton-image"></div>
                    <div className="skeleton-details">
                      <div className="skeleton-title"></div>
                      <div className="skeleton-info"></div>
                      <div className="skeleton-info"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="items-details ord-details">
          <div className="skeleton-coupon-section">
            <div className="skeleton-coupon-code order-coupon"></div>
            <div className="skeleton-total">
              <div className="skeleton-total-row gift"></div>
              <div className="skeleton-total-row"></div>
            </div>
            <div className="skeleton-checkout-btn checkout">
              <button className="primary-btn"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCart;
