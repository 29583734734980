import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import NavMenu from "./NavMenu";
import api from "../../api";
import { useAuth } from "../../context/AuthContext";
import CurrencySelector from "../currencySelector/CurrencySelector";
import { countries } from "countries-list";
import AlertPopup from "../alert/AlertPopup";
import SearchComponent from "../search/SearchComponent";

const countryList = Object.values(countries);

const Header = () => {
  const navigate = useNavigate();
  const { cartCount, addItemToCart } = useCart();
  const { apiToken, userRole, logout } = useAuth();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [navIconOpen, setNavIconOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [visibleSubmenu, setVisibleSubmenu] = useState(null);

  let api_token = localStorage.getItem("apiToken");

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  const handleNavIconClick = () => {
    setNavIconOpen((prevState) => !prevState);

    setMenuOpen((prevState) => {
      const newState = !prevState;

      if (newState) {
        document.body.classList.add("menuopen");
      } else {
        document.body.classList.remove("menuopen");
      }

      return newState;
    });
  };
  const handleLogout = () => {
    logout();
    navigate("/home");
  };
  return (
    <header>
      <div className="hdr-top">
        <div className="container">
          {/* <div className="social-media">
                        <ul>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/instagram.png`} alt="instagram" />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/youtube.png`} alt="youtube" />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/twitter.png`} alt="twitter" />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank">
                                    <img src={`${process.env.PUBLIC_URL}/images/facebook.png`} alt="facebook" />
                                </Link>
                            </li>
                        </ul>
                    </div> */}

          <div className="language">
            <ul>
              <CurrencySelector />
              {/* <select className="has-sub-menu sub-cunt" disabled>
                              <option >  Modestrend </option>
                            </select> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="hdr-mid">
        <div className="container">
          <div className="left-menu">
            <button
              id="nav-icon2"
              className={`nav-icon ${navIconOpen ? "open active" : ""}`}
              onClick={handleNavIconClick}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className="logo header-log">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/topnavi.png`}
                alt="Logo"
              />
            </Link>
          </div>
          <SearchComponent />
          <div className="right-function">
            <div className="language">
              <ul>
                <CurrencySelector />
              </ul>
            </div>
            <ul className="account-icons">
              <li>
                <Link to="/cart">
                  <img
                    src="/images/cart.svg"
                    height={18}
                    width={18}
                    alt="Cart"
                  />
                  {cartCount > 0 && (
                    <div className="cart-icon">
                      <sup>{cartCount}</sup>
                    </div>
                  )}
                </Link>
              </li>

              {apiToken && userRole === "shopper" ? (
                <>
                  <li className={`profile ${isHovered ? "active" : ""}`}>
                    <Link to="#">
                      <img
                        src="/images/ic_profile_blue.svg"
                        height={18}
                        width={18}
                        alt="Profile"
                      />
                    </Link>
                    <ul className="profile-container">
                      <li className="item">
                        <Link to="/my-favorites">
                          <span>
                            <img
                              src="/images/favorite.png"
                              height={18}
                              width={18}
                              alt="Favorites"
                            />
                          </span>
                          Favorites
                        </Link>
                      </li>
                      <li className="item">
                        <Link to="/myorder">
                          <span>
                            <img
                              height={18}
                              width={18}
                              src="/images/my_orders.svg"
                              alt="My Orders"
                            />
                          </span>
                          My Order
                        </Link>
                      </li>
                      <li className="item">
                        <button onClick={handleLogout}>
                          <span>
                            <img
                              src="/images/logout.svg"
                              height={18}
                              width={18}
                              alt="Logout"
                            />
                          </span>
                          Logout
                        </button>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/login">
                    <img
                      src="/images/profile.svg"
                      height={18}
                      width={18}
                      alt="Login"
                    />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className={`megamenu ${menuOpen ? "active" : ""}`}>
        <NavMenu apitokenData={api_token} />
      </div>
      <div
        className="js-nav-side__overlay nav-side__overlay"
        style={{ left: "270px", opacity: "0.8" }}
        onClick={handleNavIconClick}
      ></div>
      <AlertPopup isOpen={isPopupOpen} onClose={handlePopupClose} />
    </header>
  );
};

export default Header;
